import { RouteProps } from '../../routes/AppRouter'
import { Fab, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import CachedIcon from '@material-ui/icons/Cached'
import { useForm } from '../../common/utils/form-generation/useForm'
import { useTranslation } from 'react-i18next'
import { navigate } from '@reach/router'
import { URL_PATIENTS } from '../../routes/routes-constants'
import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { FormAction, FormActions } from '../../common/utils/form-generation'
import { Query, QueryParam } from '../../common/api/Query'
import { Clinic, ClinicQuery } from '../../modules/clinics/models/Clinic'
import { getClinicContainer } from '../../container/clinic-modules'
import { ClinicService } from '../../modules/clinics/services/ClinicService'
import { CLINIC_SERVICE_KEY } from '../../modules/clinics'
import { getPatientContainer } from '../../container/patient-module'
import { PatientService } from '../../modules/patients/services/PatientService'
import { PATIENT_PARAM_SERVICE_KEY, PATIENT_SERVICE_KEY } from '../../modules/patients'
import { Patient, PatientDTO } from '../../modules/patients/models/Patient'
import { EntitySelect } from '../common/EntitySelect'
import { userGenders } from '../../modules/users/enums/GenderType'
import { patientTypes } from '../../modules/patients/enums/PatientType'
import { PatientParam } from '../../modules/patients/models/PatientParam'
import { PatientParamType } from '../../modules/patients/enums/PatientParamType'
import { PatientParamService } from '../../modules/patients/services/PatientParamService'
import { toMap } from '../../common/utils/ArrayUtils'
import { getCsvContainer } from '../../container/csv-modules'
import { CsvService } from '../../modules/csvs/services/CsvService'
import { CSV_SERVICE_KEY } from '../../modules/csvs'
import { getTranslationContainer } from '../../container/translation-module'
import { TranslationService } from '../../modules/translations/services/TranslationService'
import { TRANSLATION_SERVICE_KEY } from '../../modules/translations'
import { Csv } from '../../modules/csvs/models/Csv'
import { useStyles } from '../../components/entity-page/EntityPage.styles'
import { Translation, TranslationQuery } from '../../modules/translations/models/Translation'
import { getAuthContainer } from '../../container/auth-modules'
import { AuthService } from '../../modules/auth/services/AuthService'
import { AUTH_SERVICE_KEY } from '../../modules/auth'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { Permission } from '../../common/enums/Permissions'

const clinicService = getClinicContainer().get<ClinicService>(CLINIC_SERVICE_KEY)
const patientParamService =
  getPatientContainer().get<PatientParamService>(PATIENT_PARAM_SERVICE_KEY)
const translationService =
  getTranslationContainer().get<TranslationService>(TRANSLATION_SERVICE_KEY)
const patientService = getPatientContainer().get<PatientService>(PATIENT_SERVICE_KEY)
const csvService = getCsvContainer().get<CsvService>(CSV_SERVICE_KEY)
const authService = getAuthContainer().get<AuthService>(AUTH_SERVICE_KEY)

export type FormProps = RouteProps & {
  patient: Patient | undefined
}

export const Form = (props: FormProps) => {
  const { t } = useTranslation()
  const classes = useStyles({})
  const [selectedFile, setSelectedFile] = useState<File>()
  const [csv, setCsv] = useState<Csv>()
  const [clinics, setClinics] = useState<Clinic[]>([])
  const [patientParams, setPatientParams] = useState<Map<PatientParamType, PatientParam[]>>(new Map())
  const [translations, setTranslations] = useState<Map<string, Translation[]>>(new Map())

  useEffect(() => {
    if (!props.patient) {
      return
    }
    setData(props.patient.toDTO())
    csvService
      .getFilteredList(
        new Query({
          query: [new QueryParam('patient', props.patient?.id)],
        }),
      )
      .subscribe((value) => value && value.count > 0 && setCsv(value.items[0]))
  }, [props.patient])

  useEffect(() => {
    const query = []
    if (!authService.get().permissions.includes(Permission.viewAll)) {
      query.push(new QueryParam<ClinicQuery>('ids', authService.get().clinics))
    }
    clinicService
      .getFilteredList(
        new Query({
          query,
          sort: [{ field: 'name' }],
        }),
      )
      .subscribe((res) => setClinics(res.items))

    patientParamService
      .getFilteredList(
        new Query({
          sort: [{ field: 'value' }],
        }),
      )
      .subscribe((res) => setPatientParams(toMap(res.items, (item) => item.type)))

    translationService
      .getFilteredList(
        new Query({
          query: [
            new QueryParam<TranslationQuery>('context', [
              'eyeColor',
              'hairColor',
              'skinColor',
              'ethnicity',
            ]),
            new QueryParam<TranslationQuery>('lang', authService.get().language),
          ],
          sort: [{ field: 'text' }],
        }),
      )
      .subscribe((res) => setTranslations(toMap(res.items, (item) => item.context)))
  }, [])

  const handleFileInput = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const files = (event.target as HTMLInputElement).files
    files && files.length && setSelectedFile(files[0])
  }

  const { handleChange, handleSubmit, data, setData, errors } = useForm<PatientDTO>({
    validations: {
      clinicID: {
        required: {
          value: true,
          message: t('clinicNotValidError'),
        },
      },
      sampleName: {
        required: {
          value: true,
          message: t('sampleNameNotValidError'),
        },
      },
      idPatient: {
        required: {
          value: true,
          message: t('idPatientNotValidError'),
        },
      },
      firstName: {
        required: {
          value: true,
          message: t('firstNameNotValidError'),
        },
      },
      lastName: {
        required: {
          value: true,
          message: t('lastNameNotValidError'),
        },
      },
      gender: {
        required: {
          value: true,
          message: t('genderNotValidError'),
        },
      },
      type: {
        required: {
          value: true,
          message: t('patientTypeNotValidError'),
        },
      },
      eyeColor: {
        required: {
          value: true,
          message: t('eyeColorNotValidError'),
        },
      },
      ethnicity: {
        required: {
          value: true,
          message: t('ethnicityNotValidError'),
        },
      },
      skinColor: {
        required: {
          value: true,
          message: t('skinColorNotValidError'),
        },
      },
      hairColor: {
        required: {
          value: true,
          message: t('hairColorNotValidError'),
        },
      },
    },

    onSubmit: () => {
      function storeCb(value: Patient | undefined) {
        if (value) {
          if (selectedFile) {
            csvService.uploadFile(value.id, selectedFile).subscribe(() => goToPatients())
          } else {
            goToPatients().then()
          }
        }
      }

      //const finalDate = new Date()
      //if (data.dob) {
        //finalDate.setDate(new Date(data.dob).getDate())
      //}

      if (!data.id) {
        patientService
          .add({
            ...data,
            id: uuidv4(),
            //dob: finalDate,
            csvDate: selectedFile ? new Date() : data.csvDate,
          })
          .subscribe((value) => storeCb(value))
        return
      }
      patientService
        .update({ ...data, csvDate: selectedFile ? new Date() : data.csvDate })
        .subscribe((value) => storeCb(value))
    },

    initialValues: props.patient && props.patient.toDTO(),
  })

  const inputFile = useRef<HTMLInputElement | null>(null)

  const goToPatients = () => navigate(URL_PATIENTS)

  const actions: FormAction[] = [
    {
      label: t('back'),
      handleAction: () => goToPatients(),
    },
  ]

  const translatedOptions = (params: PatientParam[], type: string): any[] =>
    params.map((p) => ({
        id: p.id,
        type: p.type,
        value: p.value,
        translation: (translations.get(type) || []).find((t) => p.value === t.text)?.translation,
      }),
    )

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <EntitySelect
            name={'name'}
            value={data && data.clinicID}
            options={clinics}
            error={errors['clinicID']}
            onChange={(value) => handleChange('clinicID', value && value.id)}
            label={t('clinic')}
            pk={'id'}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            variant={'outlined'}
            error={errors['sampleName'] !== undefined}
            id={'name'}
            type={'string'}
            onChange={(event) => handleChange('sampleName', event.target.value)}
            value={(data && data.sampleName) || ''}
            label={t('sampleName')}
            helperText={errors['sampleName']}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            variant={'outlined'}
            error={errors['idPatient'] !== undefined}
            id={'name'}
            type={'string'}
            onChange={(event) => handleChange('idPatient', event.target.value)}
            value={(data && data.idPatient) || ''}
            label={t('idPatient')}
            helperText={errors['idPatient']}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            variant={'outlined'}
            error={errors['firstName'] !== undefined}
            id={'firstName'}
            type={'string'}
            onChange={(event) => handleChange('firstName', event.target.value)}
            value={(data && data.firstName) || ''}
            label={t('firstName')}
            helperText={errors['firstName']}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            variant={'outlined'}
            error={errors['lastName'] !== undefined}
            id={'lastName'}
            type={'string'}
            onChange={(event) => handleChange('lastName', event.target.value)}
            value={(data && data.lastName) || ''}
            label={t('lastName')}
            helperText={errors['lastName']}
          />
        </Grid>
        <Grid item xs={12}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              style={{ width: '100%' }}
              id='dob'
              autoOk
              inputVariant={'outlined'}
              format='dd/MM/yyyy'
              value={data.dob || null}
              onChange={(newDate) => setData(Object.assign({ ...data }, { dob: newDate }))}
              required={false}
              size={'small'}
              label={t('dob')}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth variant='outlined' error={errors['gender'] !== undefined}>
            <InputLabel id='inherit-label'>{t('gender')}</InputLabel>
            <Select
              labelId='gender-label'
              id='gender'
              fullWidth
              style={{ textAlign: 'left' }}
              value={(data && data.gender) || ''}
              onChange={(event) =>
                handleChange('gender', event.target.value && +(event.target.value as string))
              }
              label={t('gender')}>
              {Object.entries(userGenders()).map(([key, value]) => (
                <MenuItem value={key}>{value}</MenuItem>
              ))}
            </Select>
            <FormHelperText>{errors['gender']}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth variant='outlined' error={errors['type'] !== undefined}>
            <InputLabel id='type-label'>{t('patientType')}</InputLabel>
            <Select
              labelId='type-label'
              id='type'
              fullWidth
              style={{ textAlign: 'left' }}
              value={(data && data.type) || ''}
              onChange={(event) =>
                handleChange('type', event.target.value && +(event.target.value as string))
              }
              label={t('patientType')}>
              {Object.entries(patientTypes()).map(([key, value]) => (
                <MenuItem value={key} key={key}>
                  {t(value)}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{errors['type']}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            error={errors['height'] !== undefined}
            fullWidth
            variant={'outlined'}
            id='height'
            type={'number'}
            label={t('height')}
            onChange={(event) => handleChange('height', +event.target.value)}
            value={data && data.height}
            helperText={errors['height']}
            InputProps={{ inputProps: { min: 0 } }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            error={errors['geneticDiseaseHistory'] !== undefined}
            fullWidth
            id='geneticDiseaseHistory'
            label={t('geneticDiseaseHistory')}
            onChange={(event) => handleChange('geneticDiseaseHistory', event.target.value)}
            multiline
            rows={4}
            value={data && data.geneticDiseaseHistory}
            variant='outlined'
            helperText={errors['geneticDiseaseHistory']}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth variant='outlined' error={errors['isPublic'] !== undefined}>
            <InputLabel id='isPublic-label'>{t('isPublic')}</InputLabel>
            <Select
              labelId='isPublic-label'
              id='isPublic'
              fullWidth
              style={{ textAlign: 'left' }}
              value={(data && data.isPublic && 1) || 0}
              onChange={(event) => handleChange('isPublic', !!event.target.value)}
              label={t('isPublic')}>
              <MenuItem value={1} key={1}>
                {t('yes')}
              </MenuItem>
              <MenuItem value={0} key={0}>
                {t('no')}
              </MenuItem>
            </Select>
            <FormHelperText>{errors['isPublic']}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <EntitySelect
            name={'translation'}
            value={data && data.ethnicity}
            options={translatedOptions(
              patientParams.get(PatientParamType.Ethnicity) || [],
              'ethnicity',
            )}
            error={errors['ethnicity']}
            onChange={(value) => handleChange('ethnicity', value && value.id)}
            label={t('ethnicity')}
            pk={'id'}
          />
        </Grid>
        <Grid item xs={12}>
          <EntitySelect
            name={'translation'}
            value={data && data.skinColor}
            options={translatedOptions(
              patientParams.get(PatientParamType.SkinColor) || [],
              'skinColor',
            )}
            error={errors['skinColor']}
            onChange={(value) => handleChange('skinColor', value && value.id)}
            label={t('skinColor')}
            pk={'id'}
          />
        </Grid>
        <Grid item xs={12}>
          <EntitySelect
            name={'translation'}
            value={data && data.eyeColor}
            options={translatedOptions(
              patientParams.get(PatientParamType.EyesColor) || [],
              'eyeColor',
            )}
            error={errors['eyeColor']}
            onChange={(value) => handleChange('eyeColor', value && value.id)}
            label={t('eyeColor')}
            pk={'id'}
          />
        </Grid>
        <Grid item xs={12}>
          <EntitySelect
            name={'translation'}
            value={data && data.hairColor}
            options={translatedOptions(
              patientParams.get(PatientParamType.HairColor) || [],
              'hairColor',
            )}
            error={errors['hairColor']}
            onChange={(value) => handleChange('hairColor', value && value.id)}
            label={t('hairColor')}
            pk={'id'}
          />
        </Grid>
        <Grid item container justify={'flex-start'}>
          <input
            type='file'
            id='csvFile'
            accept='.csv, .txt'
            onChange={(event) => handleFileInput(event)}
            ref={inputFile}
            style={{ display: 'none' }}
          />
          <Fab
            onClick={() => inputFile.current?.click()}
            variant='extended'
            color='primary'
            aria-label='add'>
            {(csv && (
              <>
                <CachedIcon className={classes.actionButton} />
                {(selectedFile && selectedFile.name) || t('changeCsvFile')}
              </>
            )) || (
              <>
                <AddIcon className={classes.actionButton} />
                {(selectedFile && selectedFile.name) || t('addCsvFile')}
              </>
            )}
          </Fab>
        </Grid>
      </Grid>
      <FormActions actions={actions} />
    </form>
  )
}
