import { Box, Button, Container, Grid, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { COLOR_CLINICS, COLOR_SETTINGS } from '../../routes/color-constants'
import { useStyles } from './Detail.styles'
import { RouteProps } from '../../routes/AppRouter'
import React, { useEffect, useState } from 'react'
import { Actions, Field, Pager, Sort } from '../../components/table/types'
import { AppTable } from '../../components/table'
import { Query, QueryParam, SortParam } from '../../common/api/Query'
import { emptyPatientDTO, PatientDTO } from '../../modules/patients/models/Patient'
import { PatientService } from '../../modules/patients/services/PatientService'
import { PATIENT_SERVICE_KEY } from '../../modules/patients'
import { getPatientContainer } from '../../container/patient-module'
import { TITLE_TESTS } from '../../routes/title-constants'
import { Title } from '../../components/common/Title'
import { getTestContainer } from '../../container/test-module'
import { TestService } from '../../modules/tests/services/TestService'
import { TEST_SERVICE_KEY } from '../../modules/tests'
import { Test } from '../../modules/tests/models/Test'
import { TEST_TYPE_SERVICE_KEY } from '../../modules/test-types'
import { TestTypeService } from '../../modules/test-types/services/TestTypeService'
import { getTestTypeContainer } from '../../container/test-type-module'
import { TestType } from '../../modules/test-types/models/TestType'
import downloadIcon from '../../assets/table-icons/download-icon.svg'
import deleteIcon from '../../assets/table-icons/delete-icon.svg'
import { navigate } from '@reach/router'
import { URL_TEST } from '../../routes/routes-constants'
import { PatientType, patientTypes } from '../../modules/patients/enums/PatientType'
import { GenderType, userGenders } from '../../modules/users/enums/GenderType'
import { Permission } from '../../common/enums/Permissions'
import { getAuthContainer } from '../../container/auth-modules'
import { AuthService } from '../../modules/auth/services/AuthService'
import { AUTH_SERVICE_KEY } from '../../modules/auth'
import { Tests } from '../../common/enums/Enums'
import { downloadFile } from '../../common/files/file'
import { getFileContainer } from '../../container/file-module'
import { FileService } from '../../modules/files/services/FileService'
import { FILE_SERVICE_KEY } from '../../modules/files'

type DetailProps = {
  id?: string
}

const authService = getAuthContainer().get<AuthService>(AUTH_SERVICE_KEY)
const testService = getTestContainer().get<TestService>(TEST_SERVICE_KEY)
const testTypeService = getTestTypeContainer().get<TestTypeService>(TEST_TYPE_SERVICE_KEY)
const patientService = getPatientContainer().get<PatientService>(PATIENT_SERVICE_KEY)
const fileService = getFileContainer().get<FileService>(FILE_SERVICE_KEY)

export const Detail = (props: DetailProps) => {
  return (
    <Container>
      <PatientDetail {...props} />
      <PatientTests {...props} />
    </Container>
  )
}

const isExcluded = (field: string): boolean => {
  const fields = [
    'id',
    'patientParams',
    'matchings',
    'ethnicity',
    'eyeColor',
    'skinColor',
    'hairColor',
    'bioarrayCode',
    'clinicID',
  ]

  return fields.includes(field)
}

export const PatientDetail = (props: RouteProps) => {
  const { t } = useTranslation()

  const classes = useStyles({ color: COLOR_CLINICS })

  const [patient, setPatient] = useState<PatientDTO>(emptyPatientDTO())

  useEffect(() => {
    if (!props.id) {
      return
    }
    patientService.getByID(props.id).subscribe((res) => {
      if (res) {
        setPatient(res.toDTO())
      }
    })
  }, [])

  const getValue = (key: string, value: string): string => {
    switch (key) {
      case 'type':
        return t(patientTypes()[value as unknown as PatientType])
      case 'gender':
        return t(userGenders()[value as unknown as GenderType])
      case 'isPublic':
        return t(value)
      case 'csvDate':
      case 'creationDate':
      case 'dob':
        return new Date(value).toLocaleDateString()
      default:
        return value
    }
  }

  return (
    <Container className={classes.container}>
      <Grid container>
        <Grid item xs={3} alignItems={'stretch'}>
          <Box className={classes.box}>
            <Typography align={'left'} variant={'h5'} className={classes.fullName}>
              {patient.firstName + ' ' + patient.lastName}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={9}>
          <Box className={classes.box} borderLeft={1}>
            {Object.entries(patient)
              .filter(([key, _]) => !isExcluded(key))
              .map(([key, value]) => (
                <Box display='flex' mb={0} p={0}>
                  <b>{t(key)}</b>: {(value || value === false) && getValue(key, value)}
                </Box>
              ))}
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}

export const PatientTests = (props: RouteProps) => {
  const { t } = useTranslation()
  const classes = useStyles({ color: COLOR_CLINICS })
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [pager, setPager] = useState<Pager>()
  const [page, setPage] = useState<number>(0)
  const [count, setCount] = useState<number>(0)
  const [itemsPerPage, setItemsPerPage] = useState<number>(10)
  const [items, setItems] = useState<Test[]>([])
  const [sort, setSort] = useState<SortParam<Test>>({
    field: 'createdAt',
    desc: true,
  })
  const [testTypes, setTestTypes] = useState<Map<string, TestType>>(new Map())

  useEffect(() => {
    if (!isLoading) {
      return
    }
    testService
      .getFilteredList(
        new Query({
          query: [new QueryParam<Test>('patientID', props.id || '')],
          pager: { offset: page * itemsPerPage, limit: itemsPerPage },
          sort: [{ field: sort.field, desc: sort.desc }],
        }),
      )
      .subscribe((res) => {
        setIsLoading(false)
        setItems(res.items)
        setCount(res.count)
      })
  }, [isLoading])

  useEffect(() => {
    testTypeService
      .getFilteredList(
        new Query({
          pager: { offset: 0, limit: -1 },
          query: [
            new QueryParam<TestType>(
              'ids',
              items.map((i) => i.testTypeID || '').filter((s) => s !== '')),
          ],
        }),
      )
      .subscribe((res) => {
        const tmpMap = new Map<string, TestType>()
        res.items.forEach((i) => i.id && tmpMap.set(i.id, i))
        setTestTypes(tmpMap)
      })
  }, [items])

  useEffect(() => {
    setIsLoading(true)
    setPager({
      page,
      count,
      handleChangePage: handlePaginationChange,
      rowsPerPage: itemsPerPage,
      handleChangeRowsPerPage,
    })
  }, [page, count, itemsPerPage])

  const handlePaginationChange = (event: unknown, value: number) => setPage(value)

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (Number.isNaN(event.target.value)) {
      setItemsPerPage(10)
      return
    }
    setItemsPerPage(Number.parseInt(event.target.value))
  }

  const fields: Field<Test>[] = [
    {
      sortable: true,
      label: t('createdAt'),
      name: 'createdAt',
      renderFunc: (f, i) => new Date(i.createdAt).toLocaleDateString(),
    },
    {
      sortable: true,
      label: t('testType'),
      name: 'testTypeID',
      renderFunc: (field, item) => {
        const name = testTypes.get(item.testTypeID || '')?.name || ''
        return item.testTypeID === Tests.CompletePanel ? t(name) : name
      },
    },
  ]

  const createTest = () =>
    navigate(URL_TEST.replace(':patientId', props.id || '').replace(':id', 'create'))

  const downloadPDF = (t: Test) => fileService.getByID(t.pdf || '').subscribe((res) =>
    res && downloadFile(res.name, res.mimeType, res.data))

  const showDownloadPDF = (t: Test): boolean => !t.pdf

  const deleteTest = (t: Test) => testService.delete(t.id).subscribe(() => {
    fileService.delete(t.pdf  || '').subscribe((res)=>{
      setIsLoading(true)
    })
  })

  const showDeleteButton = () => !authService.userCan(Permission.deletePatientTests)

  const actions: Actions<Test> = {
    actionsColumn: t('Actions'),
    items: [
      {
        handler: downloadPDF,
        hidden: showDownloadPDF,
        icon: downloadIcon,
        label: t('Download'),
      },
      {
        handler: deleteTest,
        hidden: showDeleteButton,
        icon: deleteIcon,
        label: t('Delete'),
      },

    ],
  }

  const sortable: Sort<Test> = {
    name: sort.field,
    direction: sort.desc ? 'desc' : 'asc',
    handleSort: (field) => {
      setSort({ field: field, desc: sort.field === field ? !sort.desc : true })
      setIsLoading(true)
    },
  }

  return (
    <>
      <Title color={COLOR_SETTINGS} title={t(TITLE_TESTS)} />
      <Box justifyContent={'space-between'} mb={2}>
        {authService.userCan(Permission.editTestTypes) && (
          <Button
            className={classes.button}
            variant={'contained'}
            onClick={createTest}
            style={{ float: 'right' }}>
            {t('create')}
          </Button>
        )}
      </Box>
      <AppTable
        styleHeader={{ color: COLOR_SETTINGS }}
        actions={actions}
        fields={fields}
        items={items}
        rowKeyField={'testTypeID'}
        pager={pager}
        sort={sortable}
      />
    </>
  )
}
