import React from 'react'
import { RouteProps } from '../../routes/AppRouter'
import { Title } from '../../components/common/Title'
import { TestTypesTable } from '../../features/test-types'

export const Table = (props: RouteProps) => {
  return (
    <>
      <Title color={props.color} title={props.title || ''} />
      <TestTypesTable />
    </>
  )
}
