import { Container, ContainerItem } from '../common/container/Container'
import { getAppContainer } from './app'
import { moduleURLs } from '../config/api'
import {
  PATIENT_API_KEY,
  PATIENT_PARAM_API_KEY,
  PATIENT_PARAM_SERVICE_KEY,
  PATIENT_SERVICE_KEY,
  PATIENTS_MODULE,
} from '../modules/patients'
import { PatientService } from '../modules/patients/services/PatientService'
import { PatientApi } from '../modules/patients/api/PatientApi'
import { PatientProps, PATIENT_PARAMS_MODULE } from '../modules/patients/container'
import { PatientParamApi } from '../modules/patients/api/PatientParamApi'
import { PatientParamService } from '../modules/patients/services/PatientParamService'

let container: Container

function init(p: PatientProps) {
  const items = new Map<symbol, ContainerItem>()

  items.set(PATIENT_API_KEY, new ContainerItem({ instance: new PatientApi() }))
  items.set(
    PATIENT_SERVICE_KEY,
    new ContainerItem({
      instance: new PatientService({ apiKey: PATIENT_API_KEY }),
    })
  )

  items.set(PATIENT_PARAM_API_KEY, new ContainerItem({ instance: new PatientParamApi() }))
  items.set(
    PATIENT_PARAM_SERVICE_KEY,
    new ContainerItem({
      instance: new PatientParamService({ apiKey: PATIENT_PARAM_API_KEY }),
    })
  )

  container = new Container(items, p.parentContainer, p.config)
}

export function getPatientContainer(): Container {
  if (!container) {
    init({
      parentContainer: getAppContainer(),
      config: {
        moduleFullUrlPatient: moduleURLs[PATIENTS_MODULE],
        moduleFullUrlPatientParams: moduleURLs[PATIENT_PARAMS_MODULE],
      },
    })
  }

  return container
}
