import React from 'react'
import { RouteProps } from '../../routes/AppRouter'
import { ClinicsTable } from '../../features/clinics'
import { Title } from '../../components/common/Title'

export const Table = (props: RouteProps) => {
  return (
    <>
      <Title color={props.color} title={props.title || ''} />
      <ClinicsTable />
    </>
  )
}
