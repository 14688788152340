import React from 'react'
import { RouteProps } from '../../routes/AppRouter'
import { Title } from '../../components/common/Title'
import { TranslationForm } from '../../features/translations'
import { useParams } from '@reach/router'
import { useTranslation } from 'react-i18next'

export const Form = (props: RouteProps) => {
  const { t } = useTranslation()
  const params = useParams()
  const title = params && params.id === 'create' ? t('newTranslation') : t('Edit')

  return (
    <>
      <Title color={props.color} title={title} />
      <TranslationForm id={params.id} />
    </>
  )
}
