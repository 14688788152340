import {PatientParam, PatientParamDTO} from '../models/PatientParam'
import {Observable} from 'rxjs'
import {Query} from '../../../common/api/Query'
import {ItemList} from '../../../common/models/ItemList'
import {Container, IInit} from '../../../common/container/Container'
import {IStatusService} from '../../../common/status/StatusService'
import {IPatientParamApi} from '../api/PatientParamApi'
import {STATUS_SERVICE_KEY} from '../../../container/app'

type Props = {
    apiKey: symbol
}

export interface IPatientParamService extends IInit {
    getByID(id: string): Observable<PatientParam | undefined>
    getFilteredList(q: Query<PatientParam>): Observable<ItemList<PatientParam>>
    add(e: PatientParamDTO): Observable<PatientParam | undefined>
    update(e: PatientParamDTO): Observable<PatientParam | undefined>
    delete(id: string): Observable<boolean>
}

export class PatientParamService implements IPatientParamService {
    private readonly _apiKey: symbol
    private _container!: Container
    private _api!: IPatientParamApi
    private _statusService!: IStatusService

    constructor(p: Props) {
        this._apiKey = p.apiKey
    }

    init(c: Container) {
        this._container = c
        this._api = this._container.get<IPatientParamApi>(this._apiKey)
        this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    }

    getByID(id: string): Observable<PatientParam | undefined> {
        return this._api.getByID(id)
    }

    getFilteredList(q: Query<PatientParam>): Observable<ItemList<PatientParam>> {
        return this._api.getFilteredList(q).pipe()
    }

    add(e: PatientParamDTO): Observable<PatientParam | undefined> {
        return this._api.add(e)
    }

    delete(id: string): Observable<boolean> {
        return this._api.delete(id)
    }

    update(e: PatientParamDTO): Observable<PatientParam | undefined> {
        return this._api.update(e)
    }
}
