import React, { useEffect, useState } from 'react'
import { RouteProps } from '../../routes/AppRouter'
import { PatientsForm } from '../../features/patients'
import { Title } from '../../components/common/Title'
import { useTranslation } from 'react-i18next'
import { useParams } from '@reach/router'
import { Patient } from '../../modules/patients/models/Patient'
import { getPatientContainer } from '../../container/patient-module'
import { PatientService } from '../../modules/patients/services/PatientService'
import { PATIENT_SERVICE_KEY } from '../../modules/patients'

const patientService = getPatientContainer().get<PatientService>(PATIENT_SERVICE_KEY)

export const Form = (props: RouteProps) => {
  const { t } = useTranslation()
  const params = useParams()
  const [patient, setPatient] = useState<Patient>()
  const title = params && params.id === 'create' ? t('newPatient') : t('Edit')

  useEffect(() => {
    if (params.id === 'create') {
      return
    }
    patientService.getByID(params.id).subscribe((value) => setPatient(value))
  }, [params.id])

  return (
    <>
      <Title color={props.color} title={title || ''} />
      <PatientsForm patient={patient} />
    </>
  )
}
