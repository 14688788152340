import React, { useEffect, useState } from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useTranslation } from 'react-i18next'
import { Chip } from '@material-ui/core'
import { getProperty } from '../../common/utils/ObjectUtils'

type RenderFunction<T> = (item: T) => string

export type EntityMultipleSelectProps<T> = {
  pk: keyof T
  name: RenderFunction<T> | keyof T
  options: T[]
  label?: string
  values?: string[]
  onChange?: (value: T[]) => void
  error?: string
  className?: string
}

export function EntityMultipleSelect<T>(props: EntityMultipleSelectProps<T>) {
  const [open, setOpen] = useState(false)
  const [options, setOptions] = useState<T[]>(props.options || [])
  const [error, setError] = useState<string | undefined>()
  const [selectedValues, setSelectedValues] = useState<string[]>()
  const loading = open && options.length === 0

  useEffect(() => {
    props.values && setSelectedValues(props.values)
    setError(props.error)
    props.options && setOptions(props.options)
    return () => {
      setSelectedValues(undefined)
      setError(undefined)
      setOptions([])
    }
  }, [props])

  const getSelectedValues = (): T[] => {
    const items = options.filter((opt, index) =>
      selectedValues?.includes(getProperty(opt, props.pk) as unknown as string)
    )
    return items
  }

  const handleChange = (values: T[]) => {
    props.onChange && props.onChange(values)
  }

  const getOptionLabel = (option: T): string => {
    if (typeof props.name === 'function') {
      return props.name(option)
    }
    return getProperty(option, props.name as keyof T) as unknown as string
  }

  return (
    <Autocomplete
      id={props.label}
      multiple
      fullWidth
      className={props.className}
      open={open}
      onOpen={() => {
        setOpen(true)
      }}
      onClose={() => {
        setOpen(false)
      }}
      value={getSelectedValues()}
      getOptionSelected={(option, value) =>
        getProperty(option, props.pk) === getProperty(value, props.pk)
      }
      getOptionLabel={getOptionLabel}
      options={options}
      onChange={(event, value) => handleChange(value as T[])}
      loading={loading}
      renderTags={(value: T[], getTagProps) =>
        value.map((option: T, index: number) => (
          <Chip color={'primary'} label={getOptionLabel(option)} {...getTagProps({ index })} />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label={props.label}
          error={error !== undefined}
          variant="outlined"
          helperText={error}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  )
}
