import i18n from '../../../i18n/i18n'

export enum TestCycle {
    Natural = 1,
    Substituted,
    NotApplied = 100,
}

export const testCycles = (): Record<TestCycle, string> => ({
    [TestCycle.Natural]: i18n.t('natural'),
    [TestCycle.Substituted]: 'HRT',
    [TestCycle.NotApplied]: i18n.t('notApplied'),
})
