import { Container, ContainerItem } from '../common/container/Container'
import { UserProps } from '../modules/users/container'
import { getAppContainer } from './app'
import { moduleURLs } from '../config/api'
import { TRANSLATION_API_KEY, TRANSLATION_SERVICE_KEY, TRANSLATIONS_MODULE } from '../modules/translations'
import { TranslationService } from '../modules/translations/services/TranslationService'
import { TranslationApi } from '../modules/translations/api/TranslationApi'

let container: Container

function init(p: UserProps) {
    const items = new Map<symbol, ContainerItem>()

    items.set(TRANSLATION_API_KEY, new ContainerItem({ instance: new TranslationApi() }))
    items.set(
        TRANSLATION_SERVICE_KEY,
        new ContainerItem({
            instance: new TranslationService({ apiKey: TRANSLATION_API_KEY }),
        })
    )

    container = new Container(items, p.parentContainer, p.config)
}

export function getTranslationContainer(): Container {
    if (!container) {
        init({
            parentContainer: getAppContainer(),
            config: {
                moduleFullUrl: moduleURLs[TRANSLATIONS_MODULE],
            },
        })
    }

    return container
}
