import { navigate, RouteComponentProps } from '@reach/router'
import { useTranslation } from 'react-i18next'
import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import { Card, CardContent, InputAdornment } from '@material-ui/core'
import { Email } from '@material-ui/icons'
import { useForm } from '../../common/utils/form-generation/useForm'
import { LoginButton } from './components/Button'
import { LoginTextField } from './components/TextField'
import { URL_LOGIN } from '../../routes/routes-constants'
import { useStyles } from './Login.styles'
import { Alert } from '@material-ui/lab'
import { getAuthContainer } from '../../container/auth-modules'
import { AuthService } from '../../modules/auth/services/AuthService'
import { AUTH_SERVICE_KEY } from '../../modules/auth'

const authService = getAuthContainer().get<AuthService>(AUTH_SERVICE_KEY)

function SuccessForm(props: {
  isSuccess: boolean
  onBack: (success: boolean | undefined) => void
}) {
  const { t } = useTranslation()
  const classes = useStyles()

  const goBack = () => {
    props.onBack && props.onBack(undefined)
  }

  return (
    <>
      {(props.isSuccess && (
        <Container>
          <Alert severity={'info'}>{t('youWillReceiveAnEmailToResetPassword')}</Alert>
        </Container>
      )) || (
        <Container>
          <Alert severity={'error'}>{t('canNotResetPassword')}</Alert>
          <LoginButton
            onClick={() => goBack()}
            fullWidth
            variant={'contained'}
            color={'primary'}
            className={classes.loginButton}>
            {t('back')}
          </LoginButton>
        </Container>
      )}
    </>
  )
}

function RecoverPasswordForm(props: { onSubmit: (data: { email: string }) => void }) {
  const classes = useStyles()
  const { t } = useTranslation()

  const handleGoLogin = () => navigate(URL_LOGIN)

  const { handleSubmit, handleChange, data, errors } = useForm<{ email: string }>({
    validations: {
      email: {
        pattern: {
          value: '^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$',
          message: t('E-mail no válido'),
        },
      },
    },

    onSubmit: () => {
      props.onSubmit && props.onSubmit(data)
    },
  })

  return (
    <Container className={classes.containerForm}>
      <Typography align={'left'} component={'h1'} variant={'h5'}>
        {t('Recuperar contraseña')}
      </Typography>
      <Typography align={'left'} component={'h2'} variant={'subtitle1'}>
        {t('Introduce tu email de registro')}
      </Typography>

      <form className={classes.form} autoComplete={'off'} onSubmit={handleSubmit}>
        <LoginTextField
          variant={'filled'}
          margin={'normal'}
          required
          fullWidth
          id={'email'}
          onChange={(event) => handleChange('email', event.target.value)}
          placeholder={t('Email')}
          name={'email'}
          InputProps={{
            startAdornment: (
              <InputAdornment color={'inherit'} position={'start'}>
                <Email color={'inherit'} />
              </InputAdornment>
            ),
          }}
          autoComplete={'off'}
        />
        {errors.email && <p className={'error'}>{errors.email}</p>}
        <Grid container alignItems={'center'} justify={'space-between'}>
          <Grid item xs={3}>
            <LoginButton
              type={'submit'}
              fullWidth
              variant={'contained'}
              color={'primary'}
              className={classes.loginButton}>
              {t('remember')}
            </LoginButton>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.link} onClick={() => handleGoLogin()} align={'right'}>
              {t('goToLogin')}
            </Typography>
          </Grid>
        </Grid>
      </form>
    </Container>
  )
}

export const RecoverPassword = (props: RouteComponentProps) => {
  const classes = useStyles()
  const [success, setSuccess] = useState<boolean | undefined>()

  const sendEmail = (data: { email: string }) => {
    authService.recoverPassword({ email: data.email }).subscribe((res: boolean) => {
      if (res) {
        setSuccess(true)
      } else {
        setSuccess(false)
      }
    })
  }

  return (
    <Container className={classes.container}>
      <Grid container className={classes.root} spacing={0} alignItems="center" justify="center">
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <Grid alignItems="stretch" container spacing={0}>
              <Grid item xs={12} className={classes.innerGrid}>
                {(success !== undefined && (
                  <SuccessForm isSuccess={success} onBack={(success) => setSuccess(success)} />
                )) || <RecoverPasswordForm onSubmit={(data) => sendEmail(data)} />}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Container>
  )
}
