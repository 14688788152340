export interface TestTypeDTO {
  id: string;
  name: string;
  dateVersion: Date;
  versionNumber: number;
  genes: string[];
}

export class TestType {
  private _id: string;
  private _name: string;
  private _dateVersion: Date;
  private _versionNumber: number;
  private _genes: string[];

  constructor(p: TestTypeDTO) {
    this._id = p.id;
    this._name = p.name;
    this._dateVersion = p.dateVersion;
    this._versionNumber = p.versionNumber;
    this._genes = p.genes;
  }

  get id(): string {
    return this._id;
  }

  set id(value: string) {
    this._id = value;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get dateVersion(): Date {
    return this._dateVersion;
  }

  set dateVersion(value: Date) {
    this._dateVersion = value;
  }

  get versionNumber(): number {
    return this._versionNumber;
  }

  set versionNumber(value: number) {
    this._versionNumber = value;
  }

  get genes(): string[] {
    return this._genes;
  }

  set genes(value: string[]) {
    this._genes = value;
  }

  toDTO(): TestTypeDTO {
    return {
      id: this.id,
      name: this.name,
      dateVersion: this.dateVersion,
      versionNumber: this.versionNumber,
      genes: this.genes,
    }
  }
}

export function toModel(d: TestTypeDTO): TestType {
  return new TestType(d)
}
