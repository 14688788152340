import { Container, IInit } from '../../../common/container/Container'
import { Observable, of } from 'rxjs'
import { IStatusService } from '../../../common/status/StatusService'
import { HTTP_CLIENT_KEY, IHTTPClient } from '../../../common/api/HTTPClient'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { UserContainerConfig } from '../container'
import { catchError, map } from 'rxjs/operators'
import { Query } from '../../../common/api/Query'
import { emptyList, ItemList } from '../../../common/models/ItemList'
import { User, UserDTO, toModel } from '../models/User'
import { prepareURL } from '../../../common/api/http-helpers'
import { LoggedUser } from 'modules/auth/models/LoggedUser'
import { LoggedUserDTO, toModel as toModelLoggedUser } from '../../auth/models/LoggedUserDTO'
import {Error} from '../../users/models/Error'

export interface IUserApi extends IInit {
  getByID(id: string): Observable<User | undefined>
  getFilteredList(q: Query<User>): Observable<ItemList<User>>
  add(e: UserDTO): Observable<User | undefined>
  update(e: UserDTO): Observable<User | undefined>
  delete(id: string): Observable<boolean>
  updateProfile(u: LoggedUserDTO): Observable<LoggedUser | undefined>
}

export class UserApi implements IUserApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url = (this._container.config as UserContainerConfig).moduleFullUrl
  }

  add(e: UserDTO): Observable<User | undefined> {
    return this._httpClient.post<User>({ url: this._url, body: e }).pipe(
      map<UserDTO, User>((d) => toModel(d)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  delete(id: string): Observable<boolean> {
    return this._httpClient.delete({ url: this._url + '/' + id }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(false)
      })
    )
  }

  getByID(id: string): Observable<User | undefined> {
    return this._httpClient.get<User>({ url: `${this._url}/${id}` }).pipe(
      map<UserDTO, User>((d) => toModel(d)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  getFilteredList(q: Query<User>): Observable<ItemList<User>> {
    return this._httpClient.get<ItemList<User>>({ url: prepareURL(this._url, q) }).pipe(
      map<ItemList<UserDTO>, ItemList<User>>((dto) => {
        const itemList = emptyList<User>()
        itemList.count = dto.count
        itemList.items = dto.items.map((d) => toModel(d))
        return itemList
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(emptyList<User>())
      })
    )
  }

  update(e: UserDTO): Observable<User | undefined> {
    return this._httpClient.put<User>({ url: this._url, body: e }).pipe(
      map<UserDTO, User>((d) => toModel(d)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  updateProfile(e: LoggedUserDTO): Observable<LoggedUser | undefined> {
    return this._httpClient.put<LoggedUser>({ url: this._url + '/profile', body: e }).pipe(
      map<LoggedUserDTO, LoggedUser>((d) => toModelLoggedUser(d)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }
}
