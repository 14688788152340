import { useTranslation } from 'react-i18next'
import { useParams } from '@reach/router'
import { RouteProps } from '../../routes/AppRouter'
import { Title } from '../../components/common/Title'
import { TestTypeForm } from '../../features/test-types'
import { TestType } from '../../modules/test-types/models/TestType'
import { useEffect, useState } from 'react'
import { getTestTypeContainer } from '../../container/test-type-module'
import { TestTypeService } from '../../modules/test-types/services/TestTypeService'
import { TEST_TYPE_SERVICE_KEY } from '../../modules/test-types'

const testTypeService = getTestTypeContainer().get<TestTypeService>(TEST_TYPE_SERVICE_KEY)

export const Form = (props: RouteProps) => {
  const { t } = useTranslation()
  const params = useParams()
  const [testType, setTestType] = useState<TestType>()
  const title = params && params.id === 'create' ? t('newTestType') : t('Edit')

  useEffect(() => {
    if (params.id === 'create') {
      return
    }
    testTypeService.getByID(params.id).subscribe((value) => setTestType(value))
  }, [params.id])

  return (
    <>
      <Title title={title} color={props.color} />
      <TestTypeForm testType={testType} />
    </>
  )
}
