import { PatientParamType } from "../enums/PatientParamType";

export interface PatientParamDTO {
  id: string
  type: PatientParamType
  value: string
}

export class PatientParam {
  private _id: string;
  private _type: PatientParamType;
  private _value: string;

  constructor(p: PatientParamDTO) {
    this._id = p.id
    this._type = p.type
    this._value = p.value
  }

  get id(): string {
    return this._id;
  }

  set id(value: string) {
    this._id = value;
  }

  get type(): PatientParamType {
    return this._type;
  }

  set type(value: PatientParamType) {
    this._type = value;
  }

  get value(): string {
    return this._value;
  }

  set value(value: string) {
    this._value = value;
  }

  toDTO(): PatientParamDTO {
    return {
      id: this.id,
      type: this.type,
      value: this.value,
    }
  }
}

export function toModel(d: PatientParamDTO): PatientParam {
  return new PatientParam(d)
}