import { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { StyleProps } from '../../components/entity-page/EntityPage'

export const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  root: (props) => ({
    width: '100%',
  }),
  title: (props) => ({
    color: props.color,
  }),
  container: {
    marginTop: theme.spacing(2),
  },
  stepTitle: {
    fontSize: 26,
    fontWeight: 1000,
    color: theme.palette.grey['400'],
  },
  stepSubtitle: {
    fontSize: 20,
    fontWeight: 800,
    color: theme.palette.grey['700'],
  },
  select: {
    background: theme.palette.common.white,
    '& .MuiOutlinedInput-input': {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingRigth: theme.spacing(2),
      paddingLeft: theme.spacing(2),
    },
  },
  textInput: {
    '& .MuiOutlinedInput-input': {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingRigth: theme.spacing(2),
      paddingLeft: theme.spacing(2),
    },
    background: theme.palette.common.white,
  },
  stepBox: {
    background: theme.palette.grey['300'],
    borderRadius: 10,
    padding: theme.spacing(3),
  },
  chipsBox: {
    minHeight: theme.spacing(6),
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2),
    background: theme.palette.common.white,
  },
  button: {
    color: theme.palette.common.white,
    background: theme.palette.primary.main,
    border: 0,
  },
  buttonSecondary: {
    color: theme.palette.common.white,
    background: theme.palette.grey['700'],
    border: 0,
  },
}))
