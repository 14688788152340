export interface TranslationDTO {
  id: string
  lang: 'es' | 'en'
  text: string
  translation: string
  context: string
}

export function emptyTranslationDTO(): TranslationDTO {
  return {
    id: '',
    lang: 'es',
    context: '',
    text: '',
    translation: '',
  }
}

export class Translation {
  private _id: string
  private _lang: 'es' | 'en'
  private _text: string
  private _translation: string
  private _context: string

  constructor(p: TranslationDTO) {
    this._id = p.id
    this._lang = p.lang
    this._text = p.text
    this._translation = p.translation
    this._context = p.context
  }

  get id(): string {
    return this._id
  }

  set id(value: string) {
    this._id = value
  }

  get lang(): 'es' | 'en' {
    return this._lang
  }

  set lang(value: 'es' | 'en') {
    this._lang = value
  }

  get text(): string {
    return this._text
  }

  set text(value: string) {
    this._text = value
  }

  get translation(): string {
    return this._translation
  }

  set translation(value: string) {
    this._translation = value
  }

  get context(): string {
    return this._context
  }

  set context(value: string) {
    this._context = value
  }

  toDTO(): TranslationDTO {
    return {
      id: this.id,
      lang: this.lang,
      text: this.text,
      translation: this.translation,
      context: this.context,
    }
  }
}

export function toModel(d: TranslationDTO): Translation {
  return new Translation(d)
}

export interface TranslationQuery {
  id: string
  lang: string
  text: string
  translation: string
  context: string[]
}
