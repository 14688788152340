import { RouteComponentProps, Router } from '@reach/router'
import { AuthService } from 'modules/auth/services/AuthService'
import React, { useEffect, useState } from 'react'
import {
  URL_CHANGE_PASSWORD,
  URL_CLINIC,
  URL_CLINIC_SHOW,
  URL_CLINICS,
  URL_GENE_TYPE,
  URL_GENE_TYPES,
  URL_MATCHING_NEW,
  URL_MATCHINGS,
  URL_PARAMETER,
  URL_PARAMETERS,
  URL_PATIENT,
  URL_PATIENT_SHOW,
  URL_PATIENTS,
  URL_PROFILE_USER,
  URL_RECOVER_PASSWORD,
  URL_REGISTER,
  URL_TEST,
  URL_TEST_TYPE,
  URL_TEST_TYPES,
  URL_TRANSLATION,
  URL_TRANSLATIONS,
  URL_USER,
  URL_USERS,
} from './routes-constants'
import {
  TITLE_CLINIC,
  TITLE_CLINICS,
  TITLE_GENE_TYPES,
  TITLE_MATCHING,
  TITLE_PARAMETERS,
  TITLE_PATIENT,
  TITLE_PATIENTS,
  TITLE_PROFILE_USER,
  TITLE_TEST_TYPES,
  TITLE_TESTS,
  TITLE_TRANSLATIONS,
  TITLE_USERS,
} from './title-constants'
import { COLOR_CLINICS, COLOR_MATCHING, COLOR_PATIENTS, COLOR_PRIMARY, COLOR_SETTINGS } from './color-constants'

import { Register } from '../pages/login/Register'
import { LoggedUser } from '../modules/auth/models/LoggedUser'
import { getAuthContainer } from '../container/auth-modules'
import { AUTH_SERVICE_KEY } from '../modules/auth'
import { RecoverPassword } from '../pages/login/RecoverPassword'
import { ChangePassword } from '../pages/login/ChangePassword'
import { ClinicDetail, ClinicForm, ClinicsTable } from '../pages/clinics'
import { PatientDetail, PatientForm, PatientsTable } from '../pages/patients'
import { UserProfile } from '../pages/users/UserProfile'
import { UserForm, UsersTable } from '../pages/users'
import { TestTypeForm, TestTypesTable } from '../pages/test-types'
import { MatchingsForm, MatchingsTable } from '../pages/matchings'
import { GeneTypeForm, GeneTypesTable } from '../pages/gene-types'
import { ParameterForm, ParametersTabs } from '../pages/parameters'
import { Permission } from '../common/enums/Permissions'
import { TranslationForm, TranslationsTabs } from '../pages/translations'
import { TestForm } from '../pages/tests'
import { useTranslation } from 'react-i18next'

export type RouteProps = {
  title?: string
  subtitle?: string
  color?: string
  id?: string
} & RouteComponentProps

const authService = getAuthContainer().get<AuthService>(AUTH_SERVICE_KEY)

export const AppRouter: React.FC = () => {
  const { t } = useTranslation()

  const [user, setUser] = useState<LoggedUser>(authService.get())

  useEffect(() => {
    authService.getObservable().subscribe((u: LoggedUser) => setUser(u))
  }, [user])

  const publicRoutes = (
    <>
      <Register path={URL_REGISTER} />
      <RecoverPassword path={URL_RECOVER_PASSWORD} />
      <ChangePassword path={URL_CHANGE_PASSWORD} />
    </>
  )

  const generalRoutes = (
    <>
      {authService.userCan(Permission.editGeneTypes) && (
        <GeneTypesTable path={URL_GENE_TYPES} color={COLOR_SETTINGS} title={t(TITLE_GENE_TYPES)} />
      )}
      {authService.userCan(Permission.editGeneTypes) && (
        <GeneTypeForm path={URL_GENE_TYPE} color={COLOR_SETTINGS} title={t(TITLE_GENE_TYPES)} />
      )}
      {authService.userCan(Permission.editTestTypes) && (
        <TestTypesTable path={URL_TEST_TYPES} color={COLOR_SETTINGS} title={t(TITLE_TEST_TYPES)} />
      )}
      {authService.userCan(Permission.editTestTypes) && (
        <TestTypeForm path={URL_TEST_TYPE} color={COLOR_SETTINGS} title={t(TITLE_TEST_TYPES)} />
      )}
      {authService.userCan(Permission.viewUsers) && (
        <UsersTable path={URL_USERS} color={COLOR_SETTINGS} title={t(TITLE_USERS)} />
      )}
      {authService.userCan(Permission.editUsers) && (
        <UserForm path={URL_USER} color={COLOR_SETTINGS} title={t(TITLE_USERS)} />
      )}
      {authService.userCan(Permission.editTranslations) && (
        <TranslationsTabs
          path={URL_TRANSLATIONS}
          color={COLOR_SETTINGS}
          title={t(TITLE_TRANSLATIONS)}
        />
      )}
      {authService.userCan(Permission.editTranslations) && (
        <TranslationForm
          path={URL_TRANSLATION}
          color={COLOR_SETTINGS}
          title={t(TITLE_TRANSLATIONS)}
        />
      )}
      {authService.userCan(Permission.editPatientParams) && (
        <ParametersTabs path={URL_PARAMETERS} color={COLOR_SETTINGS} title={t(TITLE_PARAMETERS)} />
      )}
      {authService.userCan(Permission.editPatientParams) && (
        <ParameterForm path={URL_PARAMETER} color={COLOR_SETTINGS} title={t(TITLE_PARAMETERS)} />
      )}
      {authService.userCan(Permission.viewClinics) && (
        <ClinicsTable path={URL_CLINICS} color={COLOR_CLINICS} title={t(TITLE_CLINICS)} />
      )}
      {authService.userCan(Permission.editClinics) && (
        <ClinicForm path={URL_CLINIC} color={COLOR_CLINICS} title={t(TITLE_CLINIC)} />
      )}
      {authService.userCan(Permission.viewClinics) && (
        <ClinicDetail path={URL_CLINIC_SHOW} color={COLOR_CLINICS} title={t(TITLE_CLINIC)} />
      )}
      {authService.userCan(Permission.viewPatients) && (
        <PatientsTable path={URL_PATIENTS} color={COLOR_PATIENTS} title={t(TITLE_PATIENTS)} />
      )}
      {authService.userCan(Permission.editPatients) && (
        <PatientForm path={URL_PATIENT} color={COLOR_PATIENTS} title={t(TITLE_PATIENT)} />
      )}
      {authService.userCan(Permission.viewPatients) && (
        <PatientDetail path={URL_PATIENT_SHOW} color={COLOR_PATIENTS} title={t(TITLE_PATIENT)} />
      )}
      {authService.userCan(Permission.editPatientTests) && (
        <TestForm path={URL_TEST} color={COLOR_SETTINGS} title={t(TITLE_TESTS)} />
      )}
      {authService.userCan(Permission.viewMatchings) && (
        <MatchingsTable path={URL_MATCHINGS} color={COLOR_MATCHING} title={t(TITLE_MATCHING)} />
      )}
      {authService.userCan(Permission.editMatchings) && (
        <MatchingsForm path={URL_MATCHING_NEW} color={COLOR_MATCHING} title={t(TITLE_MATCHING)} />
      )}

      <UserProfile path={URL_PROFILE_USER} color={COLOR_PRIMARY} title={t(TITLE_PROFILE_USER)} />
    </>
  )

  return <Router component={'div'}>{user.id ? generalRoutes : publicRoutes}</Router>
}
