import { MenuItem } from './types'
import {
  URL_CLINICS,
  URL_GENE_TYPES,
  URL_MATCHINGS,
  URL_PARAMETERS,
  URL_PATIENTS,
  URL_RECEPTIVENESS,
  URL_SCRIPT,
  URL_TEST_TYPES,
  URL_TRANSLATIONS,
  URL_USERS,
} from '../../routes/routes-constants'
import {
  TITLE_CLINICS,
  TITLE_GENE_TYPES,
  TITLE_MATCHING,
  TITLE_PARAMETERS,
  TITLE_PATIENTS,
  TITLE_RECEPTIVENESS,
  TITLE_SCRIPT,
  TITLE_SETTINGS,
  TITLE_TEST_TYPES,
  TITLE_TRANSLATIONS,
  TITLE_USERS,
} from '../../routes/title-constants'
import { COLOR_MENU_PRIMARY } from '../../routes/color-constants'
import { getAuthContainer } from '../../container/auth-modules'
import { IAuthService } from '../../modules/auth/services/AuthService'
import { AUTH_SERVICE_KEY } from '../../modules/auth'
import { Permission } from '../../common/enums/Permissions'

const authService = getAuthContainer().get<IAuthService>(AUTH_SERVICE_KEY)

export const sideBarMenuItems = (): MenuItem[] => {
  return [
    {
      name: TITLE_SETTINGS,
      image: '/icons/icon-settings.svg',
      color: COLOR_MENU_PRIMARY,
      userAccess:
        authService.userCan(Permission.editTestTypes) ||
        authService.userCan(Permission.editGeneTypes) ||
        authService.userCan(Permission.editTranslations) ||
        authService.userCan(Permission.editPatientParams) ||
        authService.userCan(Permission.editUsers) ||
        authService.userCan(Permission.changeScript),
      items: [
        {
          name: TITLE_TEST_TYPES,
          path: URL_TEST_TYPES,
          userAccess: authService.userCan(Permission.editTestTypes),
        },
        {
          name: TITLE_GENE_TYPES,
          path: URL_GENE_TYPES,
          userAccess: authService.userCan(Permission.editGeneTypes),
        },
        {
          name: TITLE_TRANSLATIONS,
          path: URL_TRANSLATIONS,
          userAccess: authService.userCan(Permission.editTranslations),
        },
        {
          name: TITLE_PARAMETERS,
          path: URL_PARAMETERS,
          userAccess: authService.userCan(Permission.editPatientParams),
        },
        {
          name: TITLE_USERS,
          path: URL_USERS,
          userAccess: authService.userCan(Permission.editUsers),
        },
        /*{
          name: TITLE_SCRIPT,
          path: URL_SCRIPT,
          userAccess: authService.userCan(Permission.changeScript),
        },*/
      ],
    },
    {
      name: TITLE_CLINICS,
      path: URL_CLINICS,
      image: '/icons/icon-clinics.svg',
      color: COLOR_MENU_PRIMARY,
      userAccess: authService.userCan(Permission.viewClinics),
    },
    {
      name: TITLE_PATIENTS,
      path: URL_PATIENTS,
      image: '/icons/icon-patients.svg',
      color: COLOR_MENU_PRIMARY,
      userAccess: authService.userCan(Permission.viewPatients),
    },
    /*{
      name: TITLE_REPORTS,
      path: URL_REPORTS,
      image: '/icons/icon-reports.svg',
      color: COLOR_MENU_PRIMARY,
      userAccess: authService.userCan(Permission.viewReports),
    },*/

    /* {
       name: TITLE_RECEPTIVENESS,
       path: URL_RECEPTIVENESS,
       image: '/icons/icon-reports.svg',
       color: COLOR_MENU_PRIMARY,
       userAccess: (authService.userCan(Permission.uploadResults) && receptiveness),
     },*/
    {
      name: TITLE_MATCHING,
      path: URL_MATCHINGS,
      image: '/icons/icon-matching.svg',
      color: COLOR_MENU_PRIMARY,
      userAccess: authService.userCan(Permission.viewMatchings),
    },
  ]
}
