import {TestType, TestTypeDTO} from '../models/TestType'
import {Observable} from 'rxjs'
import {Query} from '../../../common/api/Query'
import {ItemList} from '../../../common/models/ItemList'
import {Container, IInit} from '../../../common/container/Container'
import {IStatusService} from '../../../common/status/StatusService'
import {ITestTypeApi} from '../api/TestTypeApi'
import {STATUS_SERVICE_KEY} from '../../../container/app'

type Props = {
    apiKey: symbol
}

export interface ITestTypeService extends IInit {
    getByID(id: string): Observable<TestType | undefined>
    getFilteredList(q: Query<TestType>): Observable<ItemList<TestType>>
    add(e: TestTypeDTO): Observable<TestType | undefined>
    update(e: TestTypeDTO): Observable<TestType | undefined>
    delete(id: string): Observable<boolean>
}

export class TestTypeService implements ITestTypeService {
    private readonly _apiKey: symbol
    private _container!: Container
    private _api!: ITestTypeApi
    private _statusService!: IStatusService

    constructor(p: Props) {
        this._apiKey = p.apiKey
    }

    init(c: Container) {
        this._container = c
        this._api = this._container.get<ITestTypeApi>(this._apiKey)
        this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    }

    getByID(id: string): Observable<TestType | undefined> {
        return this._api.getByID(id)
    }

    getFilteredList(q: Query<TestType>): Observable<ItemList<TestType>> {
        return this._api.getFilteredList(q).pipe()
    }

    add(e: TestTypeDTO): Observable<TestType | undefined> {
        return this._api.add(e)
    }

    delete(id: string): Observable<boolean> {
        return this._api.delete(id)
    }

    update(e: TestTypeDTO): Observable<TestType | undefined> {
        return this._api.update(e)
    }
}
