import { RouteProps } from '../../routes/AppRouter'
import { Grid, TextField } from '@material-ui/core'
import { TestType, TestTypeDTO } from '../../modules/test-types/models/TestType'
import { useForm } from '../../common/utils/form-generation/useForm'
import { useTranslation } from 'react-i18next'
import { formatDate } from '../../common/utils/DateUtils'
import { navigate } from '@reach/router'
import { URL_TEST_TYPES } from '../../routes/routes-constants'
import { getTestTypeContainer } from '../../container/test-type-module'
import { TestTypeService } from '../../modules/test-types/services/TestTypeService'
import { TEST_TYPE_SERVICE_KEY } from '../../modules/test-types'
import { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { FormAction, FormActions } from '../../common/utils/form-generation'
import { EntityMultipleSelect } from '../common'
import { getGeneTypeContainer } from '../../container/gene-types-modules'
import { GeneTypeService } from '../../modules/gene-types/services/GeneTypeService'
import { GENE_TYPE_SERVICE_KEY } from '../../modules/gene-types'
import { Query } from '../../common/api/Query'
import { GeneType } from '../../modules/gene-types/models/GeneType'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'



const testTypeService = getTestTypeContainer().get<TestTypeService>(TEST_TYPE_SERVICE_KEY)
const geneTypeService = getGeneTypeContainer().get<GeneTypeService>(GENE_TYPE_SERVICE_KEY)

export type FormProps = RouteProps & {
  testType: TestType | undefined
}

export const Form = (props: FormProps) => {
  const { t } = useTranslation()
  const [geneTypes, setGeneTypes] = useState<GeneType[]>([])

  const fetchGeneTypes = () => {
    geneTypeService
      .getFilteredList(
        new Query({
          pager: { offset: 0, limit: -1 },
          sort: [{ field: 'gene' }],
        })
      )
      .subscribe((res) => {
        setGeneTypes(res.items)
      })
  }

  useEffect(() => {
    props.testType && setData(props.testType.toDTO())
  }, [props.testType])

  useEffect(() => {
    fetchGeneTypes()
  }, [])

  const { handleChange, handleSubmit, data, setData, errors } = useForm<TestTypeDTO>({
    validations: {
      name: {
        required: {
          value: true,
          message: t('nameNotValidError'),
        },
      },
      genes: {
        required: {
          value: true,
          message: t('emptyGeneListError'),
        },
      },
    },

    onSubmit: () => {
      let finalDate = new Date()
      if (data.dateVersion) {
        finalDate = new Date(data.dateVersion)
      }

      if (!data.id) {
        testTypeService
          .add({ ...data, id: uuidv4(), dateVersion: finalDate })
          .subscribe(() => goToTestTypes())
      } else {
        testTypeService.update({ ...data, dateVersion: finalDate }).subscribe(() => goToTestTypes())
      }
    },

    initialValues: props.testType && props.testType.toDTO(),
  })

  const goToTestTypes = () => navigate(URL_TEST_TYPES)

  const actions: FormAction[] = [
    {
      label: t('back'),
      handleAction: () => goToTestTypes(),
    },
  ]

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            variant={'outlined'}
            error={errors['name'] !== undefined}
            id={'name'}
            type={'string'}
            onChange={(event) => handleChange('name', event.target.value)}
            value={(data && data.name) || ''}
            label={t('name')}
            helperText={errors['name']}
          />
        </Grid>
        <Grid item xs={12}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              style={{ width: '100%' }}
              error={errors['dateVersion'] !== undefined}
              id="date"
              autoOk
              label={t('dateVersion')}
              inputVariant={'outlined'}
              format="dd/MM/yyyy"
              value={data.dateVersion}
              onChange={(event) => handleChange('dateVersion', event)}
              required={true}
              size={'small'}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12}>
          <TextField
            error={errors['versionNumber'] !== undefined}
            fullWidth
            variant={'outlined'}
            id="versionNumber"
            type={'number'}
            label={t('versionNumber')}
            onChange={(event) => handleChange('versionNumber', +event.target.value)}
            value={data && data.versionNumber}
            helperText={errors['versionNumber']}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <EntityMultipleSelect
            name={'gene'}
            values={data && data.genes}
            options={geneTypes}
            error={errors['genes']}
            onChange={(values) =>
              handleChange(
                'genes',
                values.map((v) => v.id)
              )
            }
            label={t('geneTypes')}
            pk={'id'}
          />
        </Grid>
      </Grid>
      <FormActions actions={actions} />
    </form>
  )
}
