import { emptyList, ItemList } from '../../../common/models/ItemList'
import { Observable, of } from 'rxjs'
import { Container, IInit } from '../../../common/container/Container'
import { HTTP_CLIENT_KEY, IHTTPClient } from '../../../common/api/HTTPClient'
import { IStatusService } from '../../../common/status/StatusService'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { Query } from '../../../common/api/Query'
import { Patient, PatientDTO, PatientQuery, toModel } from '../models/Patient'
import { PatientContainerConfig } from '../container'
import { prepareURL } from '../../../common/api/http-helpers'
import { catchError, map } from 'rxjs/operators'

export interface IPatientApi extends IInit {
  getByID(id: string): Observable<Patient | undefined>
  getBySampleName(sampleName: string) : Observable<Patient| undefined>
  getFilteredList(q: Query<PatientQuery>): Observable<ItemList<Patient>>
  add(e: PatientDTO): Observable<Patient | undefined>
  update(e: PatientDTO): Observable<Patient | undefined>
  delete(id: string): Observable<boolean>
}

export class PatientApi implements IPatientApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url = (this._container.config as PatientContainerConfig).moduleFullUrlPatient
  }

  add(e: PatientDTO): Observable<Patient | undefined> {
    return this._httpClient.post<Patient>({ url: this._url, body: e }).pipe(
      map<PatientDTO, Patient>((d) => toModel(d)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  delete(id: string): Observable<boolean> {
    return this._httpClient.delete({ url: this._url + '/' + id }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(false)
      })
    )
  }

  getByID(id: string): Observable<Patient | undefined> {
    return this._httpClient.get<Patient>({ url: `${this._url}/${id}` }).pipe(
      map<PatientDTO, Patient>((d) => toModel(d)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  getBySampleName(sampleName: string): Observable<Patient | undefined>{
    return this._httpClient.get<Patient>({url: `${this._url}/${sampleName}`}).pipe(
      map<PatientDTO, Patient>((d) => toModel(d)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  getFilteredList(q: Query<PatientQuery>): Observable<ItemList<Patient>> {
    return this._httpClient.get<ItemList<Patient>>({ url: prepareURL(this._url, q) }).pipe(
      map<ItemList<PatientDTO>, ItemList<Patient>>((dto) => {
        const itemList = emptyList<Patient>()
        itemList.count = dto.count
        itemList.items = dto.items.map((d) => toModel(d))
        return itemList
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(emptyList<Patient>())
      })
    )
  }

  update(e: PatientDTO): Observable<Patient | undefined> {
    return this._httpClient.put<Patient>({ url: this._url, body: e }).pipe(
      map<PatientDTO, Patient>((d) => toModel(d)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }
}
