import React, { useEffect, useState } from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Query, QueryParam } from '../../common/api/Query'
import { emptyTranslationDTO, Translation } from '../../modules/translations/models/Translation'
import { getTranslationContainer } from '../../container/translation-module'
import { TranslationService } from '../../modules/translations/services/TranslationService'
import { TRANSLATION_SERVICE_KEY } from '../../modules/translations'
import { getAuthContainer } from '../../container/auth-modules'
import { IAuthService } from '../../modules/auth/services/AuthService'
import { AUTH_SERVICE_KEY } from '../../modules/auth'

const translationService =
  getTranslationContainer().get<TranslationService>(TRANSLATION_SERVICE_KEY)

const authService = getAuthContainer().get<IAuthService>(AUTH_SERVICE_KEY)

export interface TranslationsSelectProps {
  context: string
  value?: string
  label?: string
  onChange?: (value: Translation) => void
  error?: string
}

export const TranslationsSelect = (props: TranslationsSelectProps) => {
  const [open, setOpen] = useState(false)
  const [options, setOptions] = useState<Translation[]>([])
  const [error, setError] = useState<string | undefined>()
  const [selectedValue, setSelectedValue] = useState<string>()
  const loading = open && options.length === 0
  const user = authService.get()

  const fetchOptions = () => {
    translationService
      .getFilteredList(
        new Query({
          sort: [{ field: 'translation' }],
          pager: { limit: -1, offset: 0 },
          query: [new QueryParam('lang', user.language), new QueryParam('context', props.context)],
        })
      )
      .subscribe((res) => {
        setOptions(res.items)
      })
  }

  useEffect(() => {
    options && props.value && setSelectedValue(props.value)
    setError(props.error)
    return () => {
      setSelectedValue(undefined)
      setError(undefined)
    }
  }, [props])

  useEffect(() => {
    fetchOptions()
  }, [])

  const getSelectedValue = (): Translation => {
    const item = options.find((opt) => opt.text === selectedValue)
    return item || new Translation(emptyTranslationDTO())
  }

  const handleChange = (value: Translation) => {
    props.onChange && props.onChange(value)
  }

  return (
    <>
      <Autocomplete
        id="translations-select"
        fullWidth
        open={open}
        onOpen={() => {
          setOpen(true)
        }}
        onClose={() => {
          setOpen(false)
        }}
        value={getSelectedValue()}
        getOptionSelected={(option, value) => value && option.text === value.text}
        getOptionLabel={(option) => option.translation}
        options={options}
        onChange={(event, value) => handleChange(value as Translation)}
        loading={loading}
        renderInput={(params) => (
          <TextField
            {...params}
            label={props.label}
            error={error !== undefined}
            variant="outlined"
            helperText={error}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </>
  )
}
