import { RouteProps } from '../../routes/AppRouter'
import { useTranslation } from 'react-i18next'
import React, { useEffect } from 'react'
import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core'
import { navigate } from '@reach/router'
import { URL_PARAMETERS } from '../../routes/routes-constants'
import { useForm } from '../../common/utils/form-generation/useForm'
import { FormAction, FormActions } from '../../common/utils/form-generation'
import { v4 as uuidv4 } from 'uuid'
import { PatientParamDTO } from '../../modules/patients/models/PatientParam'
import { getPatientContainer } from '../../container/patient-module'
import { PatientParamService } from '../../modules/patients/services/PatientParamService'
import { PATIENT_PARAM_SERVICE_KEY } from '../../modules/patients'
import { PatientParamType, patientParamType } from '../../modules/patients/enums/PatientParamType'

const patientParamService =
  getPatientContainer().get<PatientParamService>(PATIENT_PARAM_SERVICE_KEY)

export const Form = (props: RouteProps) => {
  const { t } = useTranslation()

  const { handleSubmit, handleChange, data, setData, errors } = useForm<PatientParamDTO>({
    validations: {},
    onSubmit: () => {
      if (!data.id) {
        patientParamService.add({ ...data, id: uuidv4() }).subscribe(() => goToParameters())
      } else {
        patientParamService.update(data).subscribe(() => goToParameters())
      }
    },
  })

  const goToParameters = () => navigate(URL_PARAMETERS)

  useEffect(() => {
    if (!props.id || props.id === 'create') {
      return
    }
    patientParamService.getByID(props.id).subscribe((res) => {
      if (res) {
        setData(res.toDTO())
      }
    })
  }, [])

  const actions: FormAction[] = [
    {
      label: t('back'),
      handleAction: () => goToParameters(),
    },
  ]

  return (
    <form onSubmit={handleSubmit} autoComplete={'off'}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl fullWidth variant="outlined" error={errors['type'] !== undefined}>
            <InputLabel id="type-label">{t('type')}</InputLabel>
            <Select
              labelId="type-label"
              id="type"
              fullWidth
              style={{ textAlign: 'left' }}
              value={(data && data.type) || ''}
              onChange={(event) =>
                handleChange('type', event.target.value && +(event.target.value as string))
              }
              label={t('type')}>
              {Object.keys(patientParamType()).map((p) => (
                <MenuItem value={p} key={p}>
                  {t(patientParamType()[p as unknown as PatientParamType])}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{errors['type']}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            error={errors['value'] !== undefined}
            fullWidth
            variant={'outlined'}
            id="value"
            type={'text'}
            label={t('value')}
            onChange={(event) => handleChange('value', event.target.value)}
            value={data.value}
            helperText={errors['value']}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
      </Grid>
      <FormActions actions={actions} />
    </form>
  )
}
