import { GenderType } from '../../users/enums/GenderType'
import { v4 as uuidv4 } from 'uuid'
import { BoolQueryParam } from '../../../common/api/Query'
import { PatientType } from '../enums/PatientType'

export interface PatientDTO {
  id: string
  sampleName: string
  bioarrayCode: string
  idPatient: string
  firstName: string
  lastName: string
  dob?: Date
  creationDate: Date
  gender: GenderType
  type: PatientType
  height: number
  geneticDiseaseHistory: string
  isPublic: boolean
  csvDate: Date
  matchings: string[]
  eyeColor: string
  ethnicity: string
  skinColor: string
  hairColor: string
  clinicID: string
}

export class Patient {

  private _id: string
  private _sampleName: string
  private _bioarrayCode: string
  private _idPatient: string
  private _firstName: string
  private _lastName: string
  private _dob?: Date
  private _creationDate: Date
  private _gender: GenderType
  private _type: PatientType
  private _height: number
  private _geneticDiseaseHistory: string
  private _isPublic: boolean
  private _csvDate: Date
  private _matchings: string[]
  private _eyeColor: string
  private _ethnicity: string
  private _skinColor: string
  private _hairColor: string
  private _clinicID: string

  constructor(p: PatientDTO) {
    this._id = p.id
    this._sampleName = p.sampleName
    this._bioarrayCode = p.bioarrayCode
    this._idPatient = p.idPatient
    this._firstName = p.firstName
    this._lastName = p.lastName
    this._dob = p.dob
    this._creationDate = p.creationDate
    this._type = p.type
    this._gender = p.gender
    this._height = p.height
    this._geneticDiseaseHistory = p.geneticDiseaseHistory
    this._isPublic = p.isPublic
    this._csvDate = p.csvDate
    this._matchings = p.matchings
    this._eyeColor = p.eyeColor
    this._ethnicity = p.ethnicity
    this._skinColor = p.skinColor
    this._hairColor = p.hairColor
    this._clinicID = p.clinicID
  }

  get id(): string {
    return this._id
  }

  set id(value: string) {
    this._id = value
  }

  get bioarrayCode(): string {
    return this._bioarrayCode
  }

  set bioarrayCode(value: string) {
    this._bioarrayCode = value
  }

  get idPatient(): string {
    return this._idPatient
  }

  set idPatient(value: string) {
    this._idPatient = value
  }

  get firstName(): string {
    return this._firstName
  }

  set firstName(value: string) {
    this._firstName = value
  }

  get lastName(): string {
    return this._lastName
  }

  set lastName(value: string) {
    this._lastName = value
  }

  get fullName(): string {
    return this._firstName + ' ' + this._lastName
  }

  get dob(): Date | undefined{
    return this._dob
  }

  set dob(value: Date | undefined) {
    this._dob = value
  }

  get creationDate(): Date {
    return this._creationDate;
  }

  set creationDate(value: Date) {
    this._creationDate = value;
  }

  get gender(): GenderType {
    return this._gender
  }

  set gender(value: GenderType) {
    this._gender = value
  }

  get height(): number {
    return this._height
  }

  set height(value: number) {
    this._height = value
  }

  get geneticDiseaseHistory(): string {
    return this._geneticDiseaseHistory
  }

  set geneticDiseaseHistory(value: string) {
    this._geneticDiseaseHistory = value
  }

  get isPublic(): boolean {
    return this._isPublic
  }

  set isPublic(value: boolean) {
    this._isPublic = value
  }

  get csvDate(): Date {
    return this._csvDate
  }

  set csvDate(value: Date) {
    this._csvDate = value
  }

  get sampleName(): string {
    return this._sampleName
  }

  set sampleName(value: string) {
    this._sampleName = value
  }

  get matchings(): string[] {
    return this._matchings
  }

  set matchings(value: string[]) {
    this._matchings = value
  }

  get type(): PatientType {
    return this._type
  }

  set type(value: PatientType) {
    this._type = value
  }

  get eyeColor(): string {
    return this._eyeColor
  }

  set eyeColor(value: string) {
    this._eyeColor = value
  }

  get ethnicity(): string {
    return this._ethnicity
  }

  set ethnicity(value: string) {
    this._ethnicity = value
  }

  get skinColor(): string {
    return this._skinColor
  }

  set skinColor(value: string) {
    this._skinColor = value
  }

  get hairColor(): string {
    return this._hairColor
  }

  set hairColor(value: string) {
    this._hairColor = value
  }

  get clinicID(): string {
    return this._clinicID;
  }

  set clinicID(value: string) {
    this._clinicID = value;
  }

  toDTO(): PatientDTO {
    return {
      id: this.id,
      sampleName: this.sampleName,
      bioarrayCode: this.bioarrayCode,
      idPatient: this.idPatient,
      firstName: this.firstName,
      lastName: this.lastName,
      dob: this.dob,
      creationDate: this.creationDate,
      type: this.type,
      gender: this.gender,
      height: this.height,
      geneticDiseaseHistory: this.geneticDiseaseHistory,
      isPublic: this.isPublic,
      csvDate: this.csvDate,
      matchings: this.matchings,
      eyeColor: this.eyeColor,
      ethnicity: this.ethnicity,
      skinColor: this.skinColor,
      hairColor: this.hairColor,
      clinicID: this.clinicID,
    }
  }
}

export function toModel(d: PatientDTO): Patient {
  return new Patient(d)
}

export function emptyPatientDTO(): PatientDTO {
  return {
    id: uuidv4(),
    bioarrayCode: '',
    sampleName: '',
    idPatient: '',
    firstName: '',
    lastName: '',
    dob: undefined,
    creationDate: new Date(),
    gender: GenderType.Female,
    height: 100,
    geneticDiseaseHistory: '',
    isPublic: true,
    csvDate: new Date(),
    matchings: [],
    type: PatientType.Patient,
    ethnicity: '',
    eyeColor: '',
    hairColor: '',
    skinColor: '',
    clinicID: '',
  }
}

export interface PatientQuery {
  haveTest: BoolQueryParam
  clinics: string[]
  ids: string
  idPatient: string
  sampleName: string
  idBioarray: string
  clinicName: string
  firstName: string
  lastName: string
  csvDate: Date
  sampleNames: string[]
}
