import { Container, IInit } from 'common/container/Container'
import { IStatusService } from '../../../common/status/StatusService'
import { USER_SERVICE_KEY } from '../container'
import { IUserApi } from '../api/UserApi'
import { Observable } from 'rxjs'
import { User, UserDTO } from '../models/User'
import { Query } from '../../../common/api/Query'
import { ItemList } from '../../../common/models/ItemList'
import { LoggedUserDTO } from 'modules/auth/models/LoggedUserDTO'
import { LoggedUser } from '../../auth/models/LoggedUser'

export interface IUserService extends IInit {
  getByID(id: string): Observable<User | undefined>
  getFilteredList(q: Query<User>): Observable<ItemList<User>>
  add(e: UserDTO): Observable<User | undefined>
  update(e: UserDTO): Observable<User | undefined>
  delete(id: string): Observable<boolean>
  updateProfile(u: LoggedUserDTO): Observable<LoggedUser | undefined>
}

type Props = {
  apiKey: symbol
}

export class UserService implements IUserService {
  private readonly _apiKey: symbol
  private _api!: IUserApi
  private _container!: Container
  private _statusService!: IStatusService

  constructor(p: Props) {
    this._apiKey = p.apiKey
  }

  init(c: Container) {
    this._container = c
    this._api = this._container.get<IUserApi>(this._apiKey)
    this._statusService = this._container.get<IStatusService>(USER_SERVICE_KEY)
  }

  getByID(id: string): Observable<User | undefined> {
    return this._api.getByID(id)
  }

  getFilteredList(q: Query<User>): Observable<ItemList<User>> {
    return this._api.getFilteredList(q).pipe()
  }

  add(e: UserDTO): Observable<User | undefined> {
    return this._api.add(e)
  }

  delete(id: string): Observable<boolean> {
    return this._api.delete(id)
  }

  update(e: UserDTO): Observable<User | undefined> {
    return this._api.update(e)
  }

  updateProfile(e: LoggedUserDTO): Observable<LoggedUser | undefined> {
    return this._api.updateProfile(e)
  }
}
