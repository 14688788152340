import { Container } from "../../common/container/Container";

export type TestTypesContainerConfig = {
    moduleFullUrl: string;
};

export type TestTypesProps = {
    parentContainer: Container;
    config: TestTypesContainerConfig;
};

export const TEST_TYPE_API_KEY = Symbol("TEST_TYPE_API_KEY");
export const TEST_TYPE_SERVICE_KEY = Symbol("TEST_TYPE_SERVICE_KEY");
export const TEST_TYPES_MODULE = Symbol("TEST_TYPES_MODULE");
