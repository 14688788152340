import { navigate, RouteComponentProps, useParams } from '@reach/router'
import { useTranslation } from 'react-i18next'
import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import { Card, CardContent, InputAdornment } from '@material-ui/core'
import { Lock } from '@material-ui/icons'
import { useForm } from '../../common/utils/form-generation/useForm'
import { LoginButton } from './components/Button'
import { LoginTextField } from './components/TextField'
import { URL_LOGIN } from '../../routes/routes-constants'
import { useStyles } from './Login.styles'
import { Alert } from '@material-ui/lab'
import { ChangePasswordDTO } from '../../modules/auth/models/ChangePassword'
import { getAuthContainer } from '../../container/auth-modules'
import { AuthService } from '../../modules/auth/services/AuthService'
import { AUTH_SERVICE_KEY } from '../../modules/auth'

const authService = getAuthContainer().get<AuthService>(AUTH_SERVICE_KEY)

function SuccessForm(props: {
  isSuccess: boolean
  onBack: (success: boolean | undefined) => void
}) {
  const { t } = useTranslation()
  const classes = useStyles()

  const goLogin = () => navigate(URL_LOGIN)

  const goBack = () => {
    props.onBack && props.onBack(undefined)
  }

  return (
    <>
      {(props.isSuccess && (
        <Container>
          <Alert severity={'info'}>{t('passwordChangedSuccessfully')}</Alert>
          <LoginButton
            onClick={() => goLogin()}
            fullWidth
            variant={'contained'}
            color={'primary'}
            className={classes.loginButton}>
            {t('login')}
          </LoginButton>
        </Container>
      )) || (
        <Container>
          <Alert severity={'error'}>{t('The password could not be changed. Try again')}</Alert>
          <LoginButton
            onClick={() => goBack()}
            fullWidth
            variant={'contained'}
            color={'primary'}
            className={classes.loginButton}>
            {t('back')}
          </LoginButton>
        </Container>
      )}
    </>
  )
}

function ChangePasswordForm(props: { onSubmit: (data: ChangePasswordDTO) => void }) {
  const classes = useStyles()
  const { t } = useTranslation()

  const isPasswordValid = (value: string): boolean => data.newPassword === value

  const handleGoLogin = () => navigate(URL_LOGIN)

  const { handleSubmit, handleChange, data, errors } = useForm<ChangePasswordDTO>({
    validations: {
      newPassword: {
        pattern: {
          value: '^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$',
          message: t('passwordRequirements'),
        },
      },
      confirmNewPassword: {
        custom: {
          isValid: isPasswordValid,
          message: t('passwordsDoesntMatch'),
        },
      },
    },

    onSubmit: () => {
      props.onSubmit && props.onSubmit(data)
    },
  })

  return (
    <Container className={classes.containerForm}>
      <Typography align={'left'} component={'h1'} variant={'h5'}>
        {t('recoverPassword')}
      </Typography>
      <Typography align={'left'} component={'h2'} variant={'subtitle1'}>
        {t('introduceRegisterEmail')}
      </Typography>

      <form className={classes.form} autoComplete={'off'} onSubmit={handleSubmit}>
        <LoginTextField
          variant={'filled'}
          margin={'normal'}
          required
          fullWidth
          id={'newPassword'}
          onChange={(event) => handleChange('newPassword', event.target.value)}
          placeholder={t('password')}
          name={'newPassword'}
          type={'password'}
          InputProps={{
            startAdornment: (
              <InputAdornment color={'inherit'} position={'start'}>
                <Lock color={'inherit'} />
              </InputAdornment>
            ),
          }}
          autoComplete={'off'}
        />
        {errors.newPassword && <p className={'error'}>{errors.newPassword}</p>}
        <LoginTextField
          variant={'filled'}
          margin={'normal'}
          required
          fullWidth
          id={'confirmNewPassword'}
          onChange={(event) => handleChange('confirmNewPassword', event.target.value)}
          placeholder={t('repeatPassword')}
          name={'confirmNewPassword'}
          type={'password'}
          InputProps={{
            startAdornment: (
              <InputAdornment color={'inherit'} position={'start'}>
                <Lock color={'inherit'} />
              </InputAdornment>
            ),
          }}
          autoComplete={'off'}
        />
        {errors.confirmNewPassword && <p className={'error'}>{errors.confirmNewPassword}</p>}
        <Grid container alignItems={'center'} justify={'space-between'}>
          <Grid item xs={3}>
            <LoginButton
              type={'submit'}
              fullWidth
              variant={'contained'}
              color={'primary'}
              className={classes.loginButton}>
              {t('change')}
            </LoginButton>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.link} onClick={() => handleGoLogin()} align={'right'}>
              {t('goToLogin')}
            </Typography>
          </Grid>
        </Grid>
      </form>
    </Container>
  )
}

export const ChangePassword = (props: RouteComponentProps) => {
  const classes = useStyles()
  const [success, setSuccess] = useState<boolean | undefined>()
  const params = useParams()

  const changePassword = (data: ChangePasswordDTO) => {
    authService.changePasswordWithToken(data, params.token).subscribe((res: boolean) => {
      if (res) {
        setSuccess(true)
      } else {
        setSuccess(false)
      }
    })
  }

  return (
    <Container className={classes.container}>
      <Grid container className={classes.root} spacing={0} alignItems={'center'} justify={'center'}>
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <Grid alignItems={'stretch'} container spacing={0}>
              <Grid item xs={12} className={classes.innerGrid}>
                {(success !== undefined && (
                  <SuccessForm isSuccess={success} onBack={(success) => setSuccess(success)} />
                )) || <ChangePasswordForm onSubmit={(data) => changePassword(data)} />}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Container>
  )
}
