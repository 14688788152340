export interface CsvDTO {
  id: string
  position: string
  gene: string
  transcript: string
  dnaChange: string
  protChange: string
  zyg: string
  classification: string
  patient: string
}

export class Csv {
  private _id: string
  private _position: string
  private _gene: string
  private _transcript: string
  private _dnaChange: string
  private _protChange: string
  private _zyg: string
  private _classification: string
  private _patient: string

  constructor(p: CsvDTO) {
    this._id = p.id
    this._gene = p.gene
    this._position = p.position
    this._transcript = p.transcript
    this._dnaChange = p.dnaChange
    this._protChange = p.protChange
    this._zyg = p.zyg
    this._classification = p.classification
    this._patient = p.patient
  }

  get id(): string {
    return this._id
  }

  set id(value: string) {
    this._id = value
  }

  get gene(): string {
    return this._gene
  }

  set gene(value: string) {
    this._gene = value
  }

  get classification(): string {
    return this._classification
  }

  set classification(value: string) {
    this._classification = value
  }

  get position(): string {
    return this._position
  }

  set position(value: string) {
    this._position = value
  }

  get transcript(): string {
    return this._transcript
  }

  set transcript(value: string) {
    this._transcript = value
  }

  get dnaChange(): string {
    return this._dnaChange
  }

  set dnaChange(value: string) {
    this._dnaChange = value
  }

  get protChange(): string {
    return this._protChange
  }

  set protChange(value: string) {
    this._protChange = value
  }

  get zyg(): string {
    return this._zyg
  }

  set zyg(value: string) {
    this._zyg = value
  }

  get patient(): string {
    return this._patient
  }

  set patient(value: string) {
    this._patient = value
  }

  toDTO(): CsvDTO {
    return {
      id: this.id,
      gene: this.gene,
      position: this.position,
      patient: this.patient,
      zyg: this.zyg,
      dnaChange: this.dnaChange,
      transcript: this.transcript,
      protChange: this.protChange,
      classification: this.classification,
    }
  }
}

export function toModel(d: CsvDTO): Csv {
  return new Csv(d)
}
