import React from 'react'
import { RouteProps } from '../../routes/AppRouter'
import { Title } from '../../components/common/Title'
import { TranslationsTabs } from '../../features/translations'

export const Tabs = (props: RouteProps) => {
  return (
    <>
      <Title color={props.color} title={props.title || ''} />
      <TranslationsTabs />
    </>
  )
}
