import { InheritType } from '../enums/inheritType'

export interface GeneTypeDTO {
  id: string
  gene: string
  inherit: InheritType
  preTestRisk: number
  postTestRisk: number
}

export class GeneType {
  private _id: string
  private _gene: string
  private _inherit: InheritType
  private _preTestRisk: number
  private _postTestRisk: number

  constructor(p: GeneTypeDTO) {
    this._id = p.id
    this._gene = p.gene
    this._inherit = p.inherit
    this._preTestRisk = p.preTestRisk
    this._postTestRisk = p.postTestRisk
  }

  get id(): string {
    return this._id
  }

  set id(value: string) {
    this._id = value
  }

  get gene(): string {
    return this._gene
  }

  set gene(value: string) {
    this._gene = value
  }

  get inherit(): InheritType {
    return this._inherit
  }

  set inherit(value: InheritType) {
    this._inherit = value
  }

  get preTestRisk(): number {
    return this._preTestRisk
  }

  set preTestRisk(value: number) {
    this._preTestRisk = value
  }

  get postTestRisk(): number {
    return this._postTestRisk
  }

  set postTestRisk(value: number) {
    this._postTestRisk = value
  }

  toDTO(): GeneTypeDTO {
    return {
      id: this.id,
      gene: this.gene,
      inherit: this.inherit,
      preTestRisk: this.preTestRisk,
      postTestRisk: this.postTestRisk,
    }
  }
}

export function toModel(d: GeneTypeDTO): GeneType {
  return new GeneType(d)
}
