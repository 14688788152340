import { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { StyleProps } from '../../components/entity-page/EntityPage'

export const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  title: (props) => ({
    color: props.color,
  }),
  box: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
  itemBox: {
    padding: theme.spacing(2),
  },
  button: {
    color: theme.palette.common.white,
    background: theme.palette.primary.main,
    border: 0,
  },
  bottomGrid: {
    display: 'flex',
    minHeight: '15vh',
  },
  container: {
    marginBottom: 40,
    border: '1px solid #e0e0e0',
  },
  gridValues: { display: 'flex', justifyContent: 'left', marginTop: '1px' },
  boxTypes: {
    fontSize: '16px',
    marginTop: '2%',
  },
  gridKeys: { display: 'flex', justifyContent: 'left' },
}))
