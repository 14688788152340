import { RouteProps } from '../../routes/AppRouter'
import { useTranslation } from 'react-i18next'
import React, { useEffect } from 'react'
import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core'
import { navigate } from '@reach/router'
import { URL_TRANSLATIONS } from '../../routes/routes-constants'
import { useForm } from '../../common/utils/form-generation/useForm'
import { FormAction, FormActions } from '../../common/utils/form-generation'
import { TranslationDTO } from '../../modules/translations/models/Translation'
import { getTranslationContainer } from '../../container/translation-module'
import { TranslationService } from '../../modules/translations/services/TranslationService'
import { TRANSLATION_SERVICE_KEY } from '../../modules/translations'
import { v4 as uuidv4 } from 'uuid'

const translationService =
  getTranslationContainer().get<TranslationService>(TRANSLATION_SERVICE_KEY)

const languages = ['es', 'en']
const parameters = ['pathology', 'eyeColor', 'hairColor', 'skinColor', 'ethnicity']

export const Form = (props: RouteProps) => {
  const { t } = useTranslation()

  const { handleSubmit, handleChange, data, setData, errors } = useForm<TranslationDTO>({
    validations: {
      lang: {
        required: {
          value: true,
          message: t('langIsRequired'),
        },
      },
      text: {
        required: {
          value: true,
          message: t('textIsRequired'),
        },
      },
      translation: {
        required: {
          value: true,
          message: t('translationIsRequired'),
        },
      },
      context: {
        required: {
          value: true,
          message: t('contextIsRequired'),
        },
      },
    },
    onSubmit: () => {
      if (!data.id) {
        translationService
          .add({ ...data, id: uuidv4() })
          .subscribe(() => goToTranslations())
      } else {
        translationService.update(data).subscribe(() => goToTranslations())
      }
    },
  })

  useEffect(() => {
    if (!props.id || props.id === 'create') {
      return
    }
    translationService.getByID(props.id).subscribe((res) => {
      if (res) {
        setData(res.toDTO())
      }
    })
  }, [])

  const goToTranslations = () => navigate(URL_TRANSLATIONS)

  const actions: FormAction[] = [
    {
      label: t('back'),
      handleAction: () => goToTranslations(),
    },
  ]

  return (
    <form onSubmit={handleSubmit} autoComplete={'off'}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl fullWidth variant="outlined" error={errors['lang'] !== undefined}>
            <InputLabel id="lang-label">{t('lang')}</InputLabel>
            <Select
              labelId="lang-label"
              id="lang"
              fullWidth
              style={{ textAlign: 'left' }}
              value={(data && data.lang) || ''}
              onChange={(event) => handleChange('lang', event.target.value)}
              label={t('lang')}>
              {languages.map((l) => (
                <MenuItem value={l}>{t(l)}</MenuItem>
              ))}
            </Select>
            <FormHelperText>{errors['lang']}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            error={errors['text'] !== undefined}
            fullWidth
            variant={'outlined'}
            id="text"
            type={'text'}
            label={t('text')}
            onChange={(event) => handleChange('text', event.target.value)}
            value={data.text}
            helperText={errors['text']}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            error={errors['translation'] !== undefined}
            fullWidth
            variant={'outlined'}
            id="translation"
            type={'text'}
            label={t('translation')}
            onChange={(event) => handleChange('translation', event.target.value)}
            value={data.translation}
            helperText={errors['translation']}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth variant="outlined" error={errors['context'] !== undefined}>
            <InputLabel id="context-label">{t('context')}</InputLabel>
            <Select
              labelId="context-label"
              id="context"
              fullWidth
              style={{ textAlign: 'left' }}
              value={(data && data.context) || ''}
              onChange={(event) => handleChange('context', event.target.value)}
              label={t('context')}>
              {parameters.map((l) => (
                <MenuItem value={l}>{t(l)}</MenuItem>
              ))}
            </Select>
            <FormHelperText>{errors['context']}</FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
      <FormActions actions={actions} />
    </form>
  )
}
