import {GeneType, GeneTypeDTO} from '../models/GeneType'
import {Observable} from 'rxjs'
import {Query} from '../../../common/api/Query'
import {ItemList} from '../../../common/models/ItemList'
import {Container, IInit} from '../../../common/container/Container'
import {IStatusService} from '../../../common/status/StatusService'
import {IGeneTypeApi} from '../api/GeneTypeApi'
import {STATUS_SERVICE_KEY} from '../../../container/app'

type Props = {
    apiKey: symbol
}

export interface IGeneTypeService extends IInit {
    getByID(id: string): Observable<GeneType | undefined>
    getFilteredList(q: Query<GeneType>): Observable<ItemList<GeneType>>
    add(e: GeneTypeDTO): Observable<GeneType | undefined>
    update(e: GeneTypeDTO): Observable<GeneType | undefined>
    delete(id: string): Observable<boolean>
}

export class GeneTypeService implements IGeneTypeService {
    private readonly _apiKey: symbol
    private _container!: Container
    private _api!: IGeneTypeApi
    private _statusService!: IStatusService

    constructor(p: Props) {
        this._apiKey = p.apiKey
    }

    init(c: Container) {
        this._container = c
        this._api = this._container.get<IGeneTypeApi>(this._apiKey)
        this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    }

    getByID(id: string): Observable<GeneType | undefined> {
        return this._api.getByID(id)
    }

    getFilteredList(q: Query<GeneType>): Observable<ItemList<GeneType>> {
        return this._api.getFilteredList(q).pipe()
    }

    add(e: GeneTypeDTO): Observable<GeneType | undefined> {
        return this._api.add(e)
    }

    delete(id: string): Observable<boolean> {
        return this._api.delete(id)
    }

    update(e: GeneTypeDTO): Observable<GeneType | undefined> {
        return this._api.update(e)
    }
}
