import { Container, ContainerItem } from '../common/container/Container'
import { getAppContainer } from './app'
import { moduleURLs } from '../config/api'
import {CSV_API_KEY, CSV_SERVICE_KEY, CsvProps, CSVS_MODULE} from '../modules/csvs/container'
import {CsvService} from "../modules/csvs/services/CsvService";
import {CsvApi} from "../modules/csvs/api/CsvApi";

let container: Container

function init(p: CsvProps) {
  const items = new Map<symbol, ContainerItem>()

  items.set(CSV_API_KEY, new ContainerItem({ instance: new CsvApi() }))
  items.set(
    CSV_SERVICE_KEY,
    new ContainerItem({
      instance: new CsvService({ apiKey: CSV_API_KEY }),
    })
  )

  container = new Container(items, p.parentContainer, p.config)
}

export function getCsvContainer(): Container {
  if (!container) {
    init({
      parentContainer: getAppContainer(),
      config: {
        moduleFullUrl: moduleURLs[CSVS_MODULE],
      },
    })
  }

  return container
}
