import { LoggedUser } from './LoggedUser'

export interface LoggedUserDTO {
  id: string
  firstName: string
  lastName: string
  email: string
  phone: string
  sessionExpires: Date
  language: string
  clinics: string[]
  permissions: string[]
}

export function toModel(d: LoggedUserDTO): LoggedUser {
  return new LoggedUser({
    id: d.id,
    firstName: d.firstName,
    lastName: d.lastName,
    email: d.email,
    phone: d.phone,
    sessionExpires: d.sessionExpires,
    language: d.language,
    clinics: d.clinics,
    permissions: d.permissions
  })
}

export function emptyLoggedUserDTO(): LoggedUserDTO {
  return {
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    sessionExpires: new Date(),
    language: 'es',
    clinics: [],
    permissions: [],
  }
}

export function fromModel(m: LoggedUser): LoggedUserDTO {
  return {
    id: m.id,
    firstName: m.firstName,
    lastName: m.lastName,
    email: m.email,
    phone: m.phone,
    language: m.language,
    sessionExpires: m.sessionExpires,
    clinics: m.clinics,
    permissions: m.permissions,
  }
}
