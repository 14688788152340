import { Container, ContainerItem } from '../common/container/Container'
import { getAppContainer } from './app'
import { moduleURLs } from '../config/api'
import {
  MATCHING_API_KEY,
  MATCHING_SERVICE_KEY,
  MatchingProps,
  MATCHINGS_MODULE,
} from '../modules/matchings/container'
import { MatchingService } from '../modules/matchings/services/MatchingService'
import { MatchingApi } from '../modules/matchings/api/MatchingApi'

let container: Container

function init(p: MatchingProps) {
  const items = new Map<symbol, ContainerItem>()

  items.set(MATCHING_API_KEY, new ContainerItem({ instance: new MatchingApi() }))
  items.set(
    MATCHING_SERVICE_KEY,
    new ContainerItem({
      instance: new MatchingService({ apiKey: MATCHING_API_KEY }),
    })
  )

  container = new Container(items, p.parentContainer, p.config)
}

export function getMatchingContainer(): Container {
  if (!container) {
    init({
      parentContainer: getAppContainer(),
      config: {
        moduleFullUrl: moduleURLs[MATCHINGS_MODULE],
      },
    })
  }

  return container
}
