import { v4 as uuidv4 } from 'uuid'

export interface FileDTO {
  id: string
  name: string
  data: string
  size: number
  mimeType: string
  extension: string
  ownerID: string
}

export class File {
  private readonly _id: string
  private readonly _name: string
  private readonly _data: string
  private readonly _size: number
  private readonly _mimeType: string
  private readonly _extension: string
  private readonly _ownerID: string

  constructor(p: FileDTO) {
    this._id = p.id
    this._name = p.name
    this._data = p.data
    this._size = p.size
    this._mimeType = p.mimeType
    this._extension = p.extension
    this._ownerID = p.ownerID
  }

  get id(): string {
    return this._id
  }

  get name(): string {
    return this._name
  }

  get data(): string {
    return this._data
  }

  get size(): number {
    return this._size
  }

  get mimeType(): string {
    return this._mimeType
  }

  get extension(): string {
    return this._extension
  }

  get ownerID(): string {
    return this._ownerID
  }

  toDTO(): FileDTO {
    return {
      id: this.id,
      name: this.name,
      data: this.data,
      size: this.size,
      mimeType: this.mimeType,
      extension: this.extension,
      ownerID: this.ownerID,
    }
  }
}

export function toModel(d: FileDTO): File {
  return new File(d)
}

export function emptyFileDTO(): FileDTO {
  return {
    id: uuidv4(),
    name: '',
    data: '',
    size: 0,
    mimeType: '',
    extension: '',
    ownerID: '',
  }
}