import {Test, TestDTO} from '../models/Test'
import {Observable} from 'rxjs'
import {Query} from '../../../common/api/Query'
import {ItemList} from '../../../common/models/ItemList'
import {Container, IInit} from '../../../common/container/Container'
import {IStatusService} from '../../../common/status/StatusService'
import {ITestApi} from '../api/TestApi'
import {STATUS_SERVICE_KEY} from '../../../container/app'

type Props = {
    apiKey: symbol
}

export interface ITestService extends IInit {

    getFilteredList(q: Query<Test>): Observable<ItemList<Test>>

    add(e: TestDTO): Observable<Test | undefined>

    update(e: TestDTO): Observable<Test | undefined>

    delete(id: string): Observable<boolean>
}

export class TestService implements ITestService {
    private readonly _apiKey: symbol
    private _container!: Container
    private _api!: ITestApi
    private _statusService!: IStatusService

    constructor(p: Props) {
        this._apiKey = p.apiKey
    }

    init(c: Container) {
        this._container = c
        this._api = this._container.get<ITestApi>(this._apiKey)
        this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    }

    add(e: TestDTO): Observable<Test | undefined> {
        return this._api.add(e)
    }

    delete(id: string): Observable<boolean> {
        return this._api.delete(id)
    }

    getFilteredList(q: Query<Test>): Observable<ItemList<Test>> {
        return this._api.getFilteredList(q).pipe()
    }

    update(e: TestDTO): Observable<Test | undefined> {
        return this._api.update(e)
    }
}
