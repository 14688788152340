import { COLOR_PRIMARY, COLOR_SETTINGS } from '../../routes/color-constants'
import { AppTable, Field } from '../../components/table'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { Actions, Pager, Sort } from '../../components/table/types'
import editIcon from '../../assets/table-icons/edit-icon.svg'
import { useTranslation } from 'react-i18next'
import { Query, SortParam } from '../../common/api/Query'
import { navigate } from '@reach/router'
import { URL_GENE_TYPE } from '../../routes/routes-constants'
import { GeneType } from '../../modules/gene-types/models/GeneType'
import { GENE_TYPE_SERVICE_KEY } from '../../modules/gene-types'
import { GeneTypeService } from '../../modules/gene-types/services/GeneTypeService'
import { getGeneTypeContainer } from '../../container/gene-types-modules'
import { inheritTypes } from '../../modules/gene-types/enums/inheritType'
import { Box, Button } from '@material-ui/core'
import { useStyles } from '../matchings/Matching.styles'

const geneTypeService = getGeneTypeContainer().get<GeneTypeService>(GENE_TYPE_SERVICE_KEY)

export const Table = () => {
  const { t } = useTranslation()
  const classes = useStyles({ color: COLOR_PRIMARY })

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [pager, setPager] = useState<Pager>()
  const [page, setPage] = useState<number>(0)
  const [count, setCount] = useState<number>(0)
  const [itemsPerPage, setItemsPerPage] = useState<number>(10)
  const [items, setItems] = useState<GeneType[]>([])
  const [sort, setSort] = useState<SortParam<GeneType>>({
    field: 'gene',
    desc: true,
  })

  useEffect(() => {
    if (!isLoading) {
      return
    }
    geneTypeService
      .getFilteredList(
        new Query({
          pager: { offset: page * itemsPerPage, limit: itemsPerPage },
          sort: [{ field: sort.field, desc: sort.desc }],
        })
      )
      .subscribe((res) => {
        setIsLoading(false)
        setItems(res.items)
        setCount(res.count)
      })
  }, [isLoading])

  useEffect(() => {
    setIsLoading(true)
    setPager({
      page,
      count,
      handleChangePage: handlePaginationChange,
      rowsPerPage: itemsPerPage,
      handleChangeRowsPerPage,
    })
  }, [page, count, itemsPerPage])

  const handlePaginationChange = (event: unknown, value: number) => setPage(value)

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    if (Number.isNaN(event.target.value)) {
      setItemsPerPage(10)
      return
    }
    setItemsPerPage(Number.parseInt(event.target.value))
  }

  const fields: Field<GeneType>[] = [
    {
      sortable: true,
      label: t('gene'),
      name: 'gene',
    },
    {
      sortable: true,
      label: t('inherit'),
      name: 'inherit',
      renderFunc: (f, i) => inheritTypes()[i.inherit],
    },
    {
      sortable: true,
      label: t('pretestRisk'),
      name: 'preTestRisk',
    },
    {
      sortable: true,
      label: t('posttestRisk'),
      name: 'postTestRisk',
    },
  ]

  const viewGeneType = (testType: GeneType) =>
    navigate(URL_GENE_TYPE.replace(':id', `${testType.id}`))

  const actions: Actions<GeneType> = {
    actionsColumn: t('Actions'),
    items: [
      {
        handler: viewGeneType,
        icon: editIcon,
        label: t('Edit'),
      },
    ],
  }

  const sortable: Sort<GeneType> = {
    name: sort.field,
    direction: sort.desc ? 'desc' : 'asc',
    handleSort: (field) => {
      setSort({ field: field, desc: sort.field === field ? !sort.desc : true })
      setIsLoading(true)
    },
  }

  const createGeneType = () => navigate(URL_GENE_TYPE.replace(':id', 'create'))

  return (
    <>
      <Box justifyContent={'space-between'} mb={2}>
        <Button
          className={classes.button}
          variant={'contained'}
          onClick={createGeneType}
          style={{ float: 'right' }}>
          {t('create')}
        </Button>
      </Box>
      <AppTable
        styleHeader={{ color: COLOR_SETTINGS }}
        actions={actions}
        fields={fields}
        items={items}
        rowKeyField={'id'}
        pager={pager}
        sort={sortable}
      />
    </>
  )
}
