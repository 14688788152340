import {COLOR_PRIMARY, COLOR_SETTINGS} from '../../routes/color-constants'
import {AppTable, Field} from '../../components/table'
import React, {ChangeEvent, useEffect, useState} from 'react'
import {Actions, Pager, Search, SearchValue, Sort} from '../../components/table/types'
import editIcon from '../../assets/table-icons/edit-icon.svg'
import {useTranslation} from 'react-i18next'
import {Query, QueryParam, QueryParamN, SortParam} from '../../common/api/Query'
import {navigate} from '@reach/router'
import {URL_TEST_TYPE} from '../../routes/routes-constants'
import {getTestTypeContainer} from '../../container/test-type-module'
import {TestTypeService} from '../../modules/test-types/services/TestTypeService'
import {TEST_TYPE_SERVICE_KEY} from '../../modules/test-types'
import {TestType} from '../../modules/test-types/models/TestType'
import {Box, Button} from '@material-ui/core'
import {useStyles} from '../matchings/Matching.styles'
import {Tests} from "../../common/enums/Enums";

const testTypeService = getTestTypeContainer().get<TestTypeService>(TEST_TYPE_SERVICE_KEY)

const searcherQuery = (
  svs: SearchValue<TestType>[]
): QueryParam<TestType>[] | QueryParamN<TestType>[] =>
  svs.filter((sv) => sv.value).map((sv) => ({ name: sv.name, value: sv.value as string }))

export const Table = () => {
  const { t } = useTranslation()
  const classes = useStyles({ color: COLOR_PRIMARY })

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [pager, setPager] = useState<Pager>()
  const [page, setPage] = useState<number>(0)
  const [count, setCount] = useState<number>(0)
  const [itemsPerPage, setItemsPerPage] = useState<number>(10)
  const [items, setItems] = useState<TestType[]>([])
  const [sort, setSort] = useState<SortParam<TestType>>({
    field: 'name',
    desc: true,
  })
  const [searcher, setSearcher] = useState<SearchValue<TestType>[]>([
    {
      name: 'name',
      label: t('search') + ' ' + t('byName'),
    },
    {
      name: 'dateVersion',
      label: '',
      type: 'date',
    },
    {
      name: 'versionNumber',
      label: t('search') + ' ' + t('byVersionNumber'),
      type: 'number',
    },
  ])

  useEffect(() => {
    if (!isLoading) {
      return
    }
    testTypeService
      .getFilteredList(
        new Query({
          pager: { offset: page * itemsPerPage, limit: itemsPerPage },
          sort: [{ field: sort.field, desc: sort.desc }],
          query: [...searcherQuery(searcher)],
        })
      )
      .subscribe((res) => {
        setIsLoading(false)
        setItems(res.items)
        setCount(res.count)
      })
  }, [isLoading, searcher])

  useEffect(() => {
    setIsLoading(true)
    setPager({
      page,
      count,
      handleChangePage: handlePaginationChange,
      rowsPerPage: itemsPerPage,
      handleChangeRowsPerPage,
    })
  }, [page, count, itemsPerPage])

  const handlePaginationChange = (event: unknown, value: number) => setPage(value)

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    if (Number.isNaN(event.target.value)) {
      setItemsPerPage(10)
      return
    }
    setItemsPerPage(Number.parseInt(event.target.value))
  }

  const fields: Field<TestType>[] = [
    {
      searchable: true,
      sortable: true,
      label: t('name'),
      name: 'name',
      renderFunc: (f, i) => i.id === Tests.CompletePanel ?  t(i.name) : i.name
    },
    {
      searchable: true,
      sortable: true,
      label: t('dateVersion'),
      name: 'dateVersion',
      renderFunc: (f, i) => new Date(i.dateVersion).toLocaleDateString(),
    },
    {
      searchable: true,
      sortable: true,
      label: t('versionNumber'),
      name: 'versionNumber',
    },
  ]

  const editTestType = (testType: TestType) =>
    navigate(URL_TEST_TYPE.replace(':id', `${testType.id}`))

  const hideEdit = (testType: TestType): boolean => testType.id === Tests.CompletePanel

  const actions: Actions<TestType> = {
    actionsColumn: t('Actions'),
    items: [
      {
        handler: editTestType,
        hidden: hideEdit,
        icon: editIcon,
        label: t('Edit'),
      },
    ],
  }

  const search: Search<TestType> = {
    searchValues: searcher,
    handleSearch: (svs: SearchValue<TestType>[]) => {
      const result: SearchValue<TestType>[] = []
      svs.forEach((s) => {
        if (s.type === "date" && s.value) {
          result.push(Object.assign({ ...s}, { value: new Date(s.value as string).toISOString() }))
        } else {
          result.push(s)
        }
      })
      setSearcher(result)
      setIsLoading(true)
    },
  }

  const sortable: Sort<TestType> = {
    name: sort.field,
    direction: sort.desc ? 'desc' : 'asc',
    handleSort: (field) => {
      setSort({ field: field, desc: sort.field === field ? !sort.desc : true })
      setIsLoading(true)
    },
  }

  const createTestType = () => navigate(URL_TEST_TYPE.replace(':id', 'create'))

  return (
    <>
      <Box justifyContent={'space-between'} mb={2}>
        <Button
          className={classes.button}
          variant={'contained'}
          onClick={createTestType}
          style={{ float: 'right' }}>
          {t('create')}
        </Button>
      </Box>
      <AppTable
        styleHeader={{ color: COLOR_SETTINGS }}
        actions={actions}
        fields={fields}
        items={items}
        rowKeyField={'id'}
        pager={pager}
        search={search}
        sort={sortable}
      />
    </>
  )
}
