import { Divider, Theme, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

export interface TitleProps {
  title: string
  color?: string
}

export const Title = (props: TitleProps) => {
  const classes = useStyles(props)
  return (
    <>
      <Typography className={classes.title} variant={'h3'} align="left">
        {props.title}
      </Typography>
      <Divider className={classes.divider} />
    </>
  )
}

const useStyles = makeStyles<Theme, TitleProps>((theme: Theme) => ({
  title: (props) => ({
    color: props.color,
    fontSize: 38,
    paddingBottom: theme.spacing(0),
  }),
  divider: {
    marginBottom: theme.spacing(4),
  },
}))
