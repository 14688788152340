import React from 'react';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import {makeStyles} from "@material-ui/core/styles";
import {Grid, Paper} from "@material-ui/core";
import {Profile} from "./profile/Profile";

export const TopBar = () => {
    const classes = useStyles();
    return (
        <div>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <Grid container alignItems="center" spacing={3} justify="space-between">
                        <Grid item>
                            <Paper elevation={0}>
                                <img className={classes.logo} src={"/bioarray-logo.jpeg"} />
                            </Paper>
                        </Grid>
                        <Grid item>
                            <Profile />
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            <Toolbar />
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
    },
    logo: {
      padding: "10px",
      maxHeight: "65px"
    },
    appBar: {
        background: "white",
        color: "Gray≤",
    },
    icon: {
        padding: "10px",
    },
    title: {
        margin: "auto",
    },
}))
