import { RouteProps } from '../../routes/AppRouter'
import { getTestTypeContainer } from '../../container/test-type-module'
import { TestTypeService } from '../../modules/test-types/services/TestTypeService'
import { TEST_TYPE_SERVICE_KEY } from '../../modules/test-types'
import { getTestContainer } from '../../container/test-module'
import { TestService } from '../../modules/tests/services/TestService'
import { TEST_SERVICE_KEY } from '../../modules/tests'
import { Test, TestDTO } from '../../modules/tests/models/Test'
import { getPatientContainer } from '../../container/patient-module'
import { PatientService } from '../../modules/patients/services/PatientService'
import { PATIENT_SERVICE_KEY } from '../../modules/patients'
import { FormAction, FormActions } from '../../common/utils/form-generation'
import { Grid, TextField } from '@material-ui/core'
import { EntitySelect } from 'features/common/EntitySelect'
import { navigate } from '@reach/router'
import { ChangeEvent, useEffect, useState } from 'react'
import { URL_PATIENT_SHOW } from '../../routes/routes-constants'
import { useForm } from '../../common/utils/form-generation/useForm'
import { Query, QueryParam } from '../../common/api/Query'
import { Patient } from '../../modules/patients/models/Patient'
import { useTranslation } from 'react-i18next'
import { TestType } from '../../modules/test-types/models/TestType'
import { v4 as uuidv4 } from 'uuid'
import { Tests } from '../../common/enums/Enums'
import { FILE_SERVICE_KEY } from '../../modules/files'
import { FileService } from '../../modules/files/services/FileService'
import { getFileContainer } from '../../container/file-module'
import { dataToBase64 } from '../../common/files/file'
import { getAuthContainer } from '../../container/auth-modules'
import { IAuthService } from '../../modules/auth/services/AuthService'
import { AUTH_SERVICE_KEY } from '../../modules/auth'

const testTypesService = getTestTypeContainer().get<TestTypeService>(TEST_TYPE_SERVICE_KEY)
const patientsService = getPatientContainer().get<PatientService>(PATIENT_SERVICE_KEY)
const testsService = getTestContainer().get<TestService>(TEST_SERVICE_KEY)
const fileService = getFileContainer().get<FileService>(FILE_SERVICE_KEY)
const authService = getAuthContainer().get<IAuthService>(AUTH_SERVICE_KEY)



export type FormProps = RouteProps & {
  test: Test | undefined
}

export const Form = (props: FormProps) => {
  const { t } = useTranslation()
  const [selectedFile, setSelectedFile] = useState<File>()
  const [testTypes, setTestTypes] = useState<TestType[]>([])
  const [patient, setPatient] = useState<Patient>()

  useEffect(() => {
    props.test && setData(props.test.toDTO())
  }, [props.test])

  const { handleChange, handleSubmit, data, setData, errors } = useForm<TestDTO>({
    validations: {
      patientID: {
        required: {
          value: true,
          message: t('patientNotValidError'),
        },
      },
      testTypeID: {
        required: {
          value: true,
          message: t('testTypeNotValidError'),
        },
      },
    },

    onSubmit: () => {
      if (!data.id || data.id === '') {
        const testID = uuidv4()
        testsService.add({ ...data, id: testID, createdAt: new Date() }).subscribe(async () => {
          if (selectedFile) {
            const id = uuidv4()
            const fileData = await dataToBase64(selectedFile)
            fileService.add({
              id,
              name: selectedFile.name,
              data: fileData,
              size: selectedFile.size,
              mimeType: selectedFile.type,
              extension: selectedFile.type.split('/')[1],
              ownerID: authService.get().id,
            }).subscribe(() => {
              testsService.update({ ...data, id: testID, pdf: id }).subscribe()
            })
          }
          setTimeout(() => goToPatientDetail(), 1000)
        })
      }
    },

    initialValues: props.test && props.test.toDTO(),
  })

  useEffect(() => {
    if (!data.patientID) {
      return
    }
    patientsService.getByID(data.patientID)
      .subscribe((res) => setPatient(res))
  }, [data])

  useEffect(() => {
    if (!patient) {
      return
    }
    testTypesService.getFilteredList(new Query({
      query: [new QueryParam<TestType>('clinics', [patient.clinicID])],
    })).subscribe((res) => {
      setTestTypes(res.items)
    })
  }, [patient])

  const handleFileInput = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const files = (event.target as HTMLInputElement).files
    files && files.length > 0 && setSelectedFile(files[0])
  }

  const goToPatientDetail = () => navigate(URL_PATIENT_SHOW.replace(':id', data.patientID))

  const actions: FormAction[] = [
    {
      label: t('back'),
      handleAction: () => goToPatientDetail(),
    },
  ]

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <EntitySelect
            name={(option) => option.id === Tests.CompletePanel ? t(option.name) : option.name}
            value={data && data.testTypeID}
            options={testTypes}
            error={errors['testTypeID']}
            onChange={(value) => handleChange('testTypeID', value && value.id)}
            label={t('testType')}
            pk={'id'}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            variant={'outlined'}
            id={'patientID'}
            type={'string'}
            onChange={() => {
            }}
            value={(patient && patient.fullName) || ''}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            variant={'outlined'}
            id='pdfFile'
            onChange={(event) => handleFileInput(event)}
            type={'file'}
            label={t('pdfFile')}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
      </Grid>
      <FormActions actions={actions} />
    </form>
  )
}
