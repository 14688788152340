import { MatchingType } from '../enums/MatchingType'
import { BoolQueryParam } from '../../../common/api/Query'
import { PatientQuery } from '../../patients/models/Patient'

export interface MatchingDTO {
  id: string
  mainPatientID: string
  idPatient: string
  idDonor: string
  donorID: string
  type: MatchingType
  date: Date
  result: boolean
  hideDiseases: boolean
  incompatibility: boolean
  vous: boolean
  resultNotValid: boolean
  resultNotAvailable: boolean
}

export class Matching {
  private _id: string
  private _mainPatientID: string
  private _idPatient: string
  private _idDonor: string
  private _donorID: string
  private _type: MatchingType
  private _date: Date
  private _result: boolean
  private readonly _hideDiseases: boolean
  private readonly _incompatibility: boolean
  private readonly _vous: boolean
  private readonly _resultNotValid: boolean
  private readonly _resultNotAvailable: boolean

  constructor(p: MatchingDTO) {
    this._id = p.id
    this._mainPatientID = p.mainPatientID
    this._idPatient = p.idPatient
    this._idDonor = p.idDonor
    this._donorID = p.donorID
    this._type = p.type
    this._date = p.date
    this._result = p.result
    this._hideDiseases = p.hideDiseases
    this._incompatibility = p.incompatibility
    this._vous = p.vous
    this._resultNotValid = p.resultNotValid
    this._resultNotAvailable = p.resultNotAvailable
  }

  get id(): string {
    return this._id
  }

  set id(value: string) {
    this._id = value
  }

  get mainPatientID(): string {
    return this._mainPatientID
  }

  set mainPatientID(value: string) {
    this._mainPatientID = value
  }

  get idPatient(): string {
    return this._idPatient
  }

  set idPatient(value: string) {
    this._idPatient = value
  }

  get idDonor(): string {
    return this._idDonor
  }

  set idDonor(value: string) {
    this._idDonor = value
  }

  get donorID(): string {
    return this._donorID
  }

  set donorID(value: string) {
    this._donorID = value
  }

  get type(): MatchingType {
    return this._type
  }

  set type(value: MatchingType) {
    this._type = value
  }

  get date(): Date {
    return this._date
  }

  set date(value: Date) {
    this._date = value
  }

  get result(): boolean {
    return this._result
  }

  set result(value: boolean) {
    this._result = value
  }

  get hideDiseases(): boolean {
    return this._hideDiseases
  }

  get incompatibility(): boolean {
    return this._incompatibility;
  }

  get vous(): boolean {
    return this._vous;
  }

  get resultNotValid(): boolean {
    return this._resultNotValid
  }

  get resultNotAvailable(): boolean {
    return this._resultNotAvailable
  }

  toDTO(): MatchingDTO {
    return {
      id: this.id,
      mainPatientID: this.mainPatientID,
      idPatient: this.idPatient,
      idDonor: this.idDonor,
      donorID: this.donorID,
      type: this.type,
      date: this.date,
      result: this.result,
      hideDiseases: this.hideDiseases,
      incompatibility: this.incompatibility,
      vous: this.vous,
      resultNotValid: this.resultNotValid,
      resultNotAvailable: this.resultNotAvailable
    }
  }
}

export function toModel(d: MatchingDTO): Matching {
  return new Matching(d)
}

export interface MatchingQuery {
  id: string
  idBioarray: string
  mainPatientId: string
  idPatient: string
  idDonor: string
  sampleName: string
  clinicCode: string
  clinicName: string
  firstName: string
  lastName: string
  type: number
  date: Date
  result: BoolQueryParam
}
