import { useTranslation } from 'react-i18next'
import { useParams } from '@reach/router'
import { RouteProps } from '../../routes/AppRouter'
import { Title } from '../../components/common/Title'
import { useEffect, useState } from 'react'
import { getGeneTypeContainer } from '../../container/gene-types-modules'
import { GENE_TYPE_SERVICE_KEY } from '../../modules/gene-types'
import { GeneType } from '../../modules/gene-types/models/GeneType'
import { GeneTypeService } from '../../modules/gene-types/services/GeneTypeService'
import { GeneTypeForm } from '../../features/gene-types'

const geneTypeService = getGeneTypeContainer().get<GeneTypeService>(GENE_TYPE_SERVICE_KEY)

export const Form = (props: RouteProps) => {
  const { t } = useTranslation()
  const params = useParams()
  const [geneType, setGeneType] = useState<GeneType>()
  const title = params && params.id === 'create' ? t('newGenType') : t('Edit')

  useEffect(() => {
    if (params.id === 'create') {
      return
    }
    geneTypeService.getByID(params.id).subscribe((value) => setGeneType(value))
  }, [params.id])

  return (
    <>
      <Title title={title} color={props.color} />
      <GeneTypeForm genetype={geneType} />
    </>
  )
}
