import {useTranslation} from 'react-i18next'
import {useParams} from '@reach/router'
import {RouteProps} from '../../routes/AppRouter'
import {Title} from '../../components/common/Title'
import {useEffect, useState} from 'react'
import {Test} from '../../modules/tests/models/Test'
import {TestForm} from '../../features/tests'
import {getPatientContainer} from '../../container/patient-module'
import {PatientService} from '../../modules/patients/services/PatientService'
import {PATIENT_SERVICE_KEY} from '../../modules/patients'
import {TestCycle} from "../../modules/tests/enums/TestCycle";

const patientsService = getPatientContainer().get<PatientService>(PATIENT_SERVICE_KEY)

export const Form = (props: RouteProps) => {
  const { t } = useTranslation()
  const params = useParams()
  const [test, setTest] = useState<Test>()
  const title = params && params.id === 'create' ? t('newTest') : t('Edit')

  useEffect(() => {
    if (params.id !== 'create') {
        return
    }

    patientsService.getByID(params.patientId).subscribe(
      (value) =>
        value &&
        setTest(
          new Test({
            id: '',
            testTypeID: '',
            createdAt: new Date(),
            patientID: value?.id,
            cycle: TestCycle.NotApplied,
          })
        )
    )
  }, [params.id])

  return (
    <>
      <Title title={title} color={props.color} />
      <TestForm test={test} />
    </>
  )
}
