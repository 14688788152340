import { navigate, RouteComponentProps } from '@reach/router'
import { useTranslation } from 'react-i18next'
import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import { Card, CardContent, InputAdornment } from '@material-ui/core'
import { Email, Person } from '@material-ui/icons'
import { useForm } from '../../common/utils/form-generation/useForm'
import { LoginButton } from './components/Button'
import { LoginTextField } from './components/TextField'
import { URL_LOGIN } from '../../routes/routes-constants'
import { useStyles } from './Login.styles'
import { Alert } from '@material-ui/lab'
import { AuthApi } from '../../modules/auth/api/AuthApi'
import { AUTH_SERVICE_KEY } from '../../modules/auth'
import { getAuthContainer } from '../../container/auth-modules'

const registerService = getAuthContainer().get<AuthApi>(AUTH_SERVICE_KEY)

interface FormData {
  firstName: string
  lastName: string
  email: string
}

function SuccessForm(props: {
  isSuccess: boolean
  onBack: (success: boolean | undefined) => void
}) {
  const { t } = useTranslation()
  const classes = useStyles()

  const goLogin = () => navigate(URL_LOGIN)

  const goRegister = () => {
    props.onBack && props.onBack(undefined)
  }

  return (
    <>
      {(props.isSuccess && (
        <Container>
          <Alert severity={'info'}>{t('successfulRegistration')}</Alert>
          <LoginButton
            onClick={() => goLogin()}
            fullWidth
            variant={'contained'}
            color={'primary'}
            className={classes.loginButton}>
            {t('login')}
          </LoginButton>
        </Container>
      )) || (
        <Container>
          <Alert severity={'error'}>{t('unsuccessfulRegistration')}</Alert>
          <LoginButton
            onClick={() => goRegister()}
            fullWidth
            variant={'contained'}
            color={'primary'}
            className={classes.loginButton}>
            {t('back')}
          </LoginButton>
        </Container>
      )}
    </>
  )
}

function RegisterForm(props: { onSubmit: (data: FormData) => void }) {
  const classes = useStyles()
  const { t } = useTranslation()

  const handleGoLogin = () => navigate(URL_LOGIN)

  const { handleSubmit, handleChange, data, errors } = useForm<FormData>({
    validations: {
      firstName: {
        required: {
          value: true,
          message: t('nameRequired'),
        },
      },
      lastName: {
        required: {
          value: true,
          message: t('surnameRequired'),
        },
      },
      email: {
        pattern: {
          value: '^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$',
          message: t('emailNotValidError'),
        },
      },
    },

    onSubmit: () => {
      props.onSubmit && props.onSubmit(data)
    },
  })

  return (
    <Container className={classes.containerForm}>
      <Typography align={'left'} component={'h1'} variant={'h5'}>
        {t('register')}
      </Typography>
      <Typography align={'left'} component={'h2'} variant={'subtitle1'}>
        {t('introduceUserData')}
      </Typography>

      <form className={classes.form} autoComplete={'off'} onSubmit={handleSubmit}>
        <LoginTextField
          variant={'filled'}
          margin={'normal'}
          required
          fullWidth
          id={'firstName'}
          onChange={(event) => handleChange('firstName', event.target.value)}
          placeholder={t('firstName')}
          name={'firstName'}
          InputProps={{
            startAdornment: (
              <InputAdornment color={'inherit'} position={'start'}>
                <Person color={'inherit'} />
              </InputAdornment>
            ),
          }}
          autoComplete={'off'}
        />
        {errors.firstName && <p className={'error'}>{errors.firstName}</p>}
        <LoginTextField
          variant={'filled'}
          margin={'normal'}
          required
          fullWidth
          id={'lastName'}
          onChange={(event) => handleChange('lastName', event.target.value)}
          placeholder={t('lastName')}
          name={'lastName'}
          InputProps={{
            startAdornment: (
              <InputAdornment color={'inherit'} position={'start'}>
                <Person color={'inherit'} />
              </InputAdornment>
            ),
          }}
          autoComplete={'off'}
        />
        {errors.lastName && <p className={'error'}>{errors.lastName}</p>}
        <LoginTextField
          variant={'filled'}
          margin={'normal'}
          required
          fullWidth
          id={'email'}
          onChange={(event) => handleChange('email', event.target.value)}
          placeholder={t('email')}
          name={'email'}
          InputProps={{
            startAdornment: (
              <InputAdornment color={'inherit'} position={'start'}>
                <Email color={'inherit'} />
              </InputAdornment>
            ),
          }}
          autoComplete={'off'}
        />
        {errors.email && <p className={'error'}>{errors.email}</p>}
        <Grid container alignItems={'center'} justify={'space-between'}>
          <Grid item xs={3}>
            <LoginButton
              type={'submit'}
              fullWidth
              variant={'contained'}
              color={'primary'}
              className={classes.loginButton}>
              {t('register')}
            </LoginButton>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.link} onClick={() => handleGoLogin()} align={'right'}>
              {t('login')}
            </Typography>
          </Grid>
        </Grid>
      </form>
    </Container>
  )
}

export const Register = (props: RouteComponentProps) => {
  const classes = useStyles()
  const [success, setSuccess] = useState<boolean | undefined>()

  const register = (data: FormData) => {
    registerService
      .register({
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
      })
      .subscribe((res: boolean) => {
        if (res) {
          setSuccess(true)
        } else {
          setSuccess(false)
        }
      })
  }

  return (
    <Container className={classes.container}>
      <Grid container className={classes.root} spacing={0} alignItems={'center'} justify={'center'}>
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <Grid alignItems={'stretch'} container spacing={0}>
              <Grid item xs={12} className={classes.innerGrid}>
                {(success !== undefined && (
                  <SuccessForm isSuccess={success} onBack={(success) => setSuccess(success)} />
                )) || <RegisterForm onSubmit={(data) => register(data)} />}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Container>
  )
}
