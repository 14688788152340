import { createMuiTheme } from "@material-ui/core/styles";
import { COLOR_PRIMARY } from "./routes/color-constants";
const theme = createMuiTheme({
  palette: {
    primary: {
      dark: "rgba(53, 118, 203, 1)",
      main: COLOR_PRIMARY,
    },
    grey: {
      A100: "rgba(232, 232, 232, 1)",
    },
  },
  typography: {
    h3: {
      fontWeight: 600,
    },
  },
});
export default theme;
