import React from 'react'
import { RouteProps } from '../../routes/AppRouter'
import { MatchingsForm } from '../../features/matchings'
import { COLOR_MATCHING } from '../../routes/color-constants'

export const Form = (props: RouteProps) => {
  return (
    <>
      <MatchingsForm color={COLOR_MATCHING} />
    </>
  )
}
