export enum PatientParamType {
    EyesColor = 1,
    HairColor,
    SkinColor,
    Ethnicity
}

export const patientParamType = (): Record<PatientParamType, string> => ({
  [PatientParamType.EyesColor]: 'EyesColor',
  [PatientParamType.HairColor]: 'HairColor',
  [PatientParamType.SkinColor]: 'SkinColor',
  [PatientParamType.Ethnicity]: 'Ethnicity',
})
