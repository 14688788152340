import { Container, IInit } from '../../../common/container/Container'
import { Observable } from 'rxjs'
import { Translation, TranslationDTO, TranslationQuery } from '../models/Translation'
import { ItemList } from '../../../common/models/ItemList'
import { Query } from '../../../common/api/Query'
import { ITranslationApi } from '../api/TranslationApi'
import { IStatusService } from '../../../common/status/StatusService'
import { STATUS_SERVICE_KEY } from '../../../container/app'

type Props = {
  apiKey: symbol
}

export interface ITranslationService extends IInit {
  getByID(id: string): Observable<Translation | undefined>
  getFilteredList(q: Query<TranslationQuery>): Observable<ItemList<Translation>>
  add(e: Translation): Observable<Translation | undefined>
  update(e: TranslationDTO): Observable<Translation | undefined>
  delete(id: string): Observable<boolean>
}

export class TranslationService implements ITranslationService {
  private readonly _apiKey: symbol
  private _container!: Container
  private _api!: ITranslationApi
  private _statusService!: IStatusService

  constructor(p: Props) {
    this._apiKey = p.apiKey
  }

  init(c: Container) {
    this._container = c
    this._api = this._container.get<ITranslationApi>(this._apiKey)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
  }

  add(e: TranslationDTO): Observable<Translation | undefined> {
    return this._api.add(e)
  }

  delete(id: string): Observable<boolean> {
    return this._api.delete(id)
  }

  getByID(id: string): Observable<Translation | undefined> {
    return this._api.getByID(id)
  }

  getFilteredList(q: Query<TranslationQuery>): Observable<ItemList<Translation>> {
    return this._api.getFilteredList(q)
  }

  update(e: TranslationDTO): Observable<Translation | undefined> {
    return this._api.update(e)
  }
}
