import { HTTP_CLIENT_KEY, IHTTPClient } from 'common/api/HTTPClient'
import { Container, IInit } from 'common/container/Container'
import { Observable, of } from 'rxjs'
import { GeneTypeContainerConfig } from '../container'
import { IStatusService } from '../../../common/status/StatusService'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { emptyList, ItemList } from '../../../common/models/ItemList'
import { GeneType, GeneTypeDTO, toModel } from '../models/GeneType'
import { Query } from '../../../common/api/Query'
import { catchError, map } from 'rxjs/operators'
import { prepareURL } from '../../../common/api/http-helpers'

export interface IGeneTypeApi extends IInit {
  getByID(id: string): Observable<GeneType | undefined>
  getFilteredList(q: Query<GeneType>): Observable<ItemList<GeneType>>
  add(e: GeneTypeDTO): Observable<GeneType | undefined>
  update(e: GeneTypeDTO): Observable<GeneType | undefined>
  delete(id: string): Observable<boolean>
}

export class GeneTypeApi implements IGeneTypeApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url = (this._container.config as GeneTypeContainerConfig).moduleFullUrl
  }

  getByID(id: string): Observable<GeneType | undefined> {
    return this._httpClient.get<GeneType>({ url: `${this._url}/${id}` }).pipe(
      map<GeneTypeDTO, GeneType>((d) => toModel(d)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  getFilteredList(q: Query<GeneType>): Observable<ItemList<GeneType>> {
    return this._httpClient.get<ItemList<GeneType>>({ url: prepareURL(this._url, q) }).pipe(
      map<ItemList<GeneTypeDTO>, ItemList<GeneType>>((dto) => {
        const itemList = emptyList<GeneType>()
        itemList.count = dto.count
        itemList.items = dto.items.map((d) => toModel(d))
        return itemList
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(emptyList<GeneType>())
      })
    )
  }

  add(e: GeneTypeDTO): Observable<GeneType | undefined> {
    return this._httpClient.post<GeneType>({ url: this._url, body: e }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  update(e: GeneTypeDTO): Observable<GeneType | undefined> {
    return this._httpClient.put<GeneType>({ url: this._url, body: e }).pipe(
      map<GeneTypeDTO, GeneType>((d) => toModel(d)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  delete(id: string): Observable<boolean> {
    return this._httpClient.delete({ url: `${this._url}/${id}` }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(false)
      })
    )
  }
}
