import { HTTP_CLIENT_KEY, IHTTPClient } from 'common/api/HTTPClient'
import { Container, IInit } from 'common/container/Container'
import { Observable, of } from 'rxjs'
import { TestTypesContainerConfig } from '../container'
import { IStatusService } from '../../../common/status/StatusService'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { emptyList, ItemList } from '../../../common/models/ItemList'
import { TestType, TestTypeDTO, toModel } from '../models/TestType'
import { Query } from '../../../common/api/Query'
import { catchError, map } from 'rxjs/operators'
import { prepareURL } from '../../../common/api/http-helpers'

export interface ITestTypeApi extends IInit {
  getByID(id: string): Observable<TestType | undefined>
  getFilteredList(q: Query<TestType>): Observable<ItemList<TestType>>
  add(e: TestTypeDTO): Observable<TestType | undefined>
  update(e: TestTypeDTO): Observable<TestType | undefined>
  delete(id: string): Observable<boolean>
}

export class TestTypeApi implements ITestTypeApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url = (this._container.config as TestTypesContainerConfig).moduleFullUrl
  }

  getByID(id: string): Observable<TestType | undefined> {
    return this._httpClient.get<TestType>({ url: `${this._url}/${id}` }).pipe(
      map<TestTypeDTO, TestType>((d) => toModel(d)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  getFilteredList(q: Query<TestType>): Observable<ItemList<TestType>> {
    return this._httpClient.get<ItemList<TestType>>({ url: prepareURL(this._url, q) }).pipe(
      map<ItemList<TestTypeDTO>, ItemList<TestType>>((dto) => {
        const itemList = emptyList<TestType>()
        itemList.count = dto.count
        itemList.items = dto.items.map((d) => toModel(d))
        return itemList
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(emptyList<TestType>())
      })
    )
  }

  add(e: TestTypeDTO): Observable<TestType | undefined> {
    return this._httpClient.post<TestType>({ url: this._url, body: e }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  update(e: TestTypeDTO): Observable<TestType | undefined> {
    return this._httpClient.put<TestType>({ url: this._url, body: e }).pipe(
      map<TestTypeDTO, TestType>((d) => toModel(d)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  delete(id: string): Observable<boolean> {
    return this._httpClient.delete({ url: `${this._url}/${id}` }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(false)
      })
    )
  }
}
