export const URL_LOGIN_2: string = '/'
export const URL_LOGIN: string = '/login'
export const URL_REGISTER: string = '/register'
export const URL_RECOVER_PASSWORD: string = '/recover-password'
export const URL_CHANGE_PASSWORD: string = '/change-password/:token'
export const URL_PARAMETERS: string = '/settings/parameters'
export const URL_PARAMETER: string = '/settings/parameters/:id'
export const URL_TRANSLATIONS: string = '/settings/translation'
export const URL_TRANSLATION: string = '/settings/translation/:id'
export const URL_TEST_TYPES: string = '/settings/testtypes'
export const URL_TEST_TYPE: string = '/settings/testtypes/:id'
export const URL_TESTS: string = '/patients/tests'
export const URL_TEST: string = '/patients/:patientId/tests/:id'
export const URL_GENE_TYPES: string = '/settings/genetypes'
export const URL_GENE_TYPE: string = '/settings/genetypes/:id'
export const URL_USERS: string = '/settings/users'
export const URL_USER: string = '/settings/users/:id'
export const URL_PROFILE_USER: string = '/profile'
export const URL_PATIENTS: string = '/patients'
export const URL_PATIENT: string = '/patient/:id'
export const URL_PATIENT_SHOW: string = '/patient/:id/show'
export const URL_CLINICS: string = '/clinics'
export const URL_CLINIC: string = '/clinics/:id'
export const URL_CLINIC_SHOW: string = '/clinics/:id/show'
export const URL_REPORTS: string = '/reports'
export const URL_REPORTS_DETAILS: string = '/reports/:type'
export const URL_RECEPTIVENESS: string = '/receptiveness/'
export const URL_RECEPTIVENESS_TABLE: string = '/receptiveness/table'
export const URL_MATCHINGS: string = '/matchings'
export const URL_MATCHING_NEW: string = '/matching/new'
export const URL_SCRIPT: string = '/script'