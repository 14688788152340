import { USERS_MODULE } from '../modules/users'
import { CLINICS_MODULE } from '../modules/clinics'
import { AUTHS_MODULE } from '../modules/auth'
import { TESTS_MODULE } from '../modules/tests'
import { TEST_TYPES_MODULE } from '../modules/test-types'
import { GENE_TYPES_MODULE } from '../modules/gene-types'
import { CSVS_MODULE } from '../modules/csvs'
import { MATCHINGS_MODULE } from '../modules/matchings'
import { TRANSLATIONS_MODULE } from '../modules/translations'
import { PATIENT_PARAMS_MODULE, PATIENTS_MODULE } from '../modules/patients'
import { FILES_MODULE } from '../modules/files'

const apiURL = process.env.REACT_APP_BACKEND_URL

export const moduleURLs = {
  [AUTHS_MODULE]: `${apiURL}`,
  [USERS_MODULE]: `${apiURL}/users`,
  [CLINICS_MODULE]: `${apiURL}/clinics`,
  [TESTS_MODULE]: `${apiURL}/tests`,
  [TEST_TYPES_MODULE]: `${apiURL}/test-types`,
  [GENE_TYPES_MODULE]: `${apiURL}/gene-types`,
  [CSVS_MODULE]: `${apiURL}/csvs`,
  [MATCHINGS_MODULE]: `${apiURL}/matchings`,
  [TRANSLATIONS_MODULE]: `${apiURL}/translations`,
  [PATIENTS_MODULE]: `${apiURL}/patients`,
  [PATIENT_PARAMS_MODULE]: `${apiURL}/patient-params`,
  [FILES_MODULE]: `${apiURL}/files`,
}
