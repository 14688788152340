import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import Drawer from '@material-ui/core/Drawer'
import { SideBarMenu } from './components/side-bar'
import { TopBar } from './components/top-bar'
import { Container } from '@material-ui/core'
import { AppRouter } from './routes/AppRouter'
import { IAuthService } from './modules/auth/services/AuthService'
import React, { useEffect, useState } from 'react'
import { LoggedUser } from './modules/auth/models/LoggedUser'
import { getAuthContainer } from './container/auth-modules'
import { AUTH_SERVICE_KEY } from './modules/auth'
import i18n from 'i18next'
import { Login } from './pages/login/Login'

const authService = getAuthContainer().get<IAuthService>(AUTH_SERVICE_KEY)

const App = () => {
  const classes = useStyles()

  const [loggedUser, setLoggedUser] = useState<LoggedUser>(authService.get())

  useEffect(() => {
    i18n.changeLanguage(loggedUser.language)
    authService.getObservable().subscribe((u) => {
      setLoggedUser(u)
      i18n.changeLanguage(u.language)
    })
  }, [])

  return (
    <>
      {loggedUser.id ? (
        <div className={classes.root}>
          <TopBar />
          <div className={classes.container}>
            <Drawer
              variant="permanent"
              classes={{
                paper: clsx(classes.drawer),
              }}>
              <SideBarMenu />
            </Drawer>
            <main className={classes.main}>
              <Container>
                <AppRouter />
              </Container>
            </main>
          </div>
        </div>
      ) : (
        <>
          <AppRouter />
          <Login />
        </>
      )}
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  container: {
    display: 'flex',
    flex: 1,
  },
  sidebarmenu: {
    marginTop: theme.spacing(5),
  },
  drawer: {
    background: theme.palette.grey.A100,
    position: 'static',
    transition: 'width .7s',
    width: '250px',
  },
  main: {
    flex: 1,
    background: theme.palette.common.white,
    paddingTop: theme.spacing(5),
  },
}))

export default App
