export enum PatientType {
  Patient = 1,
  DonorSemen,
  //DonorSperm,
  DonorOvule,
}

export const patientTypes = (): Record<PatientType, string> => ({
  [PatientType.Patient]: 'Patient',
  [PatientType.DonorSemen]: 'DonorSemen',
  //[PatientType.DonorSperm]: 'DonorSperm',
  [PatientType.DonorOvule]: 'DonorOvule',
})
