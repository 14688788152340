import { Container, ContainerItem } from '../common/container/Container'
import { getAppContainer } from './app'
import { moduleURLs } from '../config/api'
import { TEST_API_KEY, TEST_SERVICE_KEY, TESTS_MODULE } from '../modules/tests'
import { TestService } from '../modules/tests/services/TestService'
import { TestApi } from '../modules/tests/api/TestApi'
import { TestProps } from '../modules/tests/container'

let container: Container

function init(p: TestProps) {
  const items = new Map<symbol, ContainerItem>()

  items.set(TEST_API_KEY, new ContainerItem({ instance: new TestApi() }))
  items.set(
    TEST_SERVICE_KEY,
    new ContainerItem({
      instance: new TestService({ apiKey: TEST_API_KEY }),
    })
  )

  container = new Container(items, p.parentContainer, p.config)
}

export function getTestContainer(): Container {
  if (!container) {
    init({
      parentContainer: getAppContainer(),
      config: {
        moduleFullUrl: moduleURLs[TESTS_MODULE],
      },
    })
  }

  return container
}
