import { Button, Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useStyles } from '../../../components/entity-page/EntityPage.styles'

export interface FormAction {
  label: string
  handleAction: () => void
}

export interface FormActionsProps {
  actions?: FormAction[]
  message?: string
  disabled?: boolean
}

export const FormActions = (props: FormActionsProps) => {
  const classes = useStyles({})
  const { t } = useTranslation()

  return (
    <Grid className={classes.headerGrid} container justify={'space-between'} alignItems={'center'}>
      <Grid item xs={2}>
        {props.actions &&
          props.actions.map((action) => (
              <Button
                fullWidth
                className={classes.button}
                onClick={() => action.handleAction()}
                variant={'contained'}>
                {t(action.label)}
              </Button>
            )
          )}
      </Grid>
      <Grid item xs={2}>
        <Button fullWidth className={classes.button} type={'submit'} variant={'contained'} disabled={!!props.disabled}>
          {props.message || t('save')}
        </Button>
      </Grid>
    </Grid>
  )
}
