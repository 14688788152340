import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Button, Tab, Tabs as MaterialTabs } from '@material-ui/core'
import styles from './Tabs.module.css'
import { COLOR_PRIMARY, COLOR_SETTINGS } from '../../routes/color-constants'
import { AppTable, Field } from '../../components/table'
import { Actions, Pager, Sort } from '../../components/table/types'
import editIcon from '../../assets/table-icons/edit-icon.svg'
import { Translation, TranslationQuery } from '../../modules/translations/models/Translation'
import { Query, QueryParam, SortParam } from '../../common/api/Query'
import { getTranslationContainer } from '../../container/translation-module'
import { TranslationService } from '../../modules/translations/services/TranslationService'
import { TRANSLATION_SERVICE_KEY } from '../../modules/translations'
import { useStyles } from '../matchings/Matching.styles'
import { navigate } from '@reach/router'
import { URL_TRANSLATION } from '../../routes/routes-constants'

const parameters = ['pathology', 'eyeColor', 'hairColor', 'skinColor', 'ethnicity']

const translationService =
  getTranslationContainer().get<TranslationService>(TRANSLATION_SERVICE_KEY)

export const Tabs = () => {
  const { t } = useTranslation()
  const classes = useStyles({ color: COLOR_PRIMARY })

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [pager, setPager] = useState<Pager>()
  const [page, setPage] = useState<number>(0)
  const [count, setCount] = useState<number>(0)
  const [itemsPerPage, setItemsPerPage] = useState<number>(10)
  const [items, setItems] = useState<Translation[]>([])
  const [sort, setSort] = useState<SortParam<Translation>>({
    field: 'lang',
    desc: true,
  })
  const [tabValue, setTabValue] = useState<number>(0)

  useEffect(() => {
    if (!isLoading) {
      return
    }
    translationService
      .getFilteredList(
        new Query({
          pager: { offset: page * itemsPerPage, limit: itemsPerPage },
          sort: [{ field: sort.field, desc: sort.desc }],
          query: [new QueryParam<TranslationQuery>('context', [parameters[tabValue]])],
        })
      )
      .subscribe((res) => {
        setIsLoading(false)
        setItems(res.items)
        setCount(res.count)
      })
  }, [isLoading])

  useEffect(() => {
    setIsLoading(true)
    setPager({
      page,
      count,
      handleChangePage: handlePaginationChange,
      rowsPerPage: itemsPerPage,
      handleChangeRowsPerPage,
    })
  }, [page, count, itemsPerPage])

  const changeTabValue = (event: React.ChangeEvent<{}>, tabNumber: number) => {
    setIsLoading(true)
    setTabValue(tabNumber)
  }

  const handlePaginationChange = (event: unknown, value: number) => setPage(value)

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (Number.isNaN(event.target.value)) {
      setItemsPerPage(10)
      return
    }
    setItemsPerPage(Number.parseInt(event.target.value))
  }

  const editTranslation = (translation: Translation) =>
    navigate(URL_TRANSLATION.replace(':id', `${translation.id}`))

  const actions: Actions<Translation> = {
    actionsColumn: t('Actions'),
    items: [
      {
        handler: editTranslation,
        icon: editIcon,
        label: t('Edit'),
      },
    ],
  }

  const fields: Field<Translation>[] = [
    {
      sortable: true,
      label: t('lang'),
      name: 'lang',
      renderFunc: (f, i) => t(i.lang),
    },
    {
      sortable: true,
      label: t('text'),
      name: 'text',
    },
    {
      sortable: true,
      label: t('translation'),
      name: 'translation',
    },
  ]

  const sortable: Sort<Translation> = {
    name: sort.field,
    direction: sort.desc ? 'desc' : 'asc',
    handleSort: (field) => {
      setSort({ field: field, desc: sort.field === field ? !sort.desc : true })
      setIsLoading(true)
    },
  }
  const createTranslation = () => navigate(URL_TRANSLATION.replace(':id', 'create'))

  return (
    <Box flexGrow={'1'} display={'flex'}>
      <MaterialTabs
        orientation={'vertical'}
        variant={'scrollable'}
        indicatorColor={'primary'}
        value={tabValue}
        onChange={changeTabValue}
        className={styles.tabs}>
        {parameters.map((param) => (
          <Tab classes={{ wrapper: styles.tab }} key={param} label={t(param)} />
        ))}
      </MaterialTabs>
      <Box style={{ width: '100%' }}>
        <Box justifyContent={'space-between'} mb={2}>
          <Button
            className={classes.button}
            onClick={createTranslation}
            variant={'contained'}
            style={{ float: 'right' }}>
            {t('create')}
          </Button>
        </Box>
        <AppTable
          styleHeader={{ color: COLOR_SETTINGS }}
          actions={actions}
          fields={fields}
          items={items}
          rowKeyField={'id'}
          pager={pager}
          sort={sortable}
        />
      </Box>
    </Box>
  )
}
