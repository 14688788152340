import { Csv, CsvDTO } from '../models/Csv'
import { Observable } from 'rxjs'
import { Query } from '../../../common/api/Query'
import { ItemList } from '../../../common/models/ItemList'
import { Container, IInit } from '../../../common/container/Container'
import { IStatusService } from '../../../common/status/StatusService'
import { ICsvApi } from '../api/CsvApi'
import { STATUS_SERVICE_KEY } from '../../../container/app'

type Props = {
  apiKey: symbol
}

export interface ICsvService extends IInit {
  getByID(id: string): Observable<Csv | undefined>
  getFilteredList(q: Query<Csv>): Observable<ItemList<Csv>>
  add(e: CsvDTO): Observable<Csv | undefined>
  update(e: CsvDTO): Observable<Csv | undefined>
  delete(id: string): Observable<boolean>
  uploadFile(patientID: string, file: File): Observable<boolean>
}

export class CsvService implements ICsvService {
  private readonly _apiKey: symbol
  private _container!: Container
  private _api!: ICsvApi
  private _statusService!: IStatusService

  constructor(p: Props) {
    this._apiKey = p.apiKey
  }

  init(c: Container) {
    this._container = c
    this._api = this._container.get<ICsvApi>(this._apiKey)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
  }

  getByID(id: string): Observable<Csv | undefined> {
    return this._api.getByID(id)
  }

  getFilteredList(q: Query<Csv>): Observable<ItemList<Csv>> {
    return this._api.getFilteredList(q).pipe()
  }

  add(e: CsvDTO): Observable<Csv | undefined> {
    return this._api.add(e)
  }

  delete(id: string): Observable<boolean> {
    return this._api.delete(id)
  }

  update(e: CsvDTO): Observable<Csv | undefined> {
    return this._api.update(e)
  }

  uploadFile(patientID: string, file: File): Observable<boolean> {
    return this._api.uploadFile(patientID, file)
  }
}
