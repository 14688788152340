import { RouteProps } from '../../routes/AppRouter'
import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core'
import { useForm } from '../../common/utils/form-generation/useForm'
import { useTranslation } from 'react-i18next'
import { navigate } from '@reach/router'
import { URL_GENE_TYPES } from '../../routes/routes-constants'
import { useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { getGeneTypeContainer } from '../../container/gene-types-modules'
import { GeneTypeService } from '../../modules/gene-types/services/GeneTypeService'
import { GENE_TYPE_SERVICE_KEY } from '../../modules/gene-types'
import { GeneType, GeneTypeDTO } from '../../modules/gene-types/models/GeneType'
import { inheritTypes } from '../../modules/gene-types/enums/inheritType'
import { FormAction, FormActions } from '../../common/utils/form-generation'

const geneTypeService = getGeneTypeContainer().get<GeneTypeService>(GENE_TYPE_SERVICE_KEY)

export type FormProps = RouteProps & {
  genetype: GeneType | undefined
}

export const Form = (props: FormProps) => {
  const { t } = useTranslation()

  useEffect(() => {
    props.genetype && setData(props.genetype.toDTO())
  }, [props.genetype])

  const { handleChange, handleSubmit, data, setData, errors } = useForm<GeneTypeDTO>({
    validations: {
      gene: {
        required: {
          value: true,
          message: t('geneNotValidError'),
        },
      },
      inherit: {
        required: {
          value: true,
          message: t('inheritNotValidError'),
        },
      },
      postTestRisk: {
        required: {
          value: true,
          message: t('posttestRiskNotValidError'),
        },
      },
      preTestRisk: {
        required: {
          value: true,
          message: t('posttestRiskNotValidError'),
        },
      },
    },

    onSubmit: () => {
      if (!data.id) {
        geneTypeService.add({ ...data, id: uuidv4() }).subscribe(() => goToGeneTypes())
      } else {
        geneTypeService.update(data).subscribe(() => goToGeneTypes())
      }
    },

    initialValues: props.genetype && props.genetype.toDTO(),
  })

  const goToGeneTypes = () => navigate(URL_GENE_TYPES)

  const actions: FormAction[] = [
    {
      label: t('back'),
      handleAction: () => goToGeneTypes(),
    },
  ]

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            error={errors['gene'] !== undefined}
            fullWidth
            variant={'outlined'}
            id="gene"
            type={'text'}
            label={t('gene')}
            onChange={(event) => handleChange('gene', event.target.value)}
            value={data && data.gene}
            helperText={errors['gene']}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth variant="outlined" error={errors['inherit'] !== undefined}>
            <InputLabel id="inherit-label">{t('inherit')}</InputLabel>
            <Select
              labelId="inherit-label"
              id="inherit"
              fullWidth
              style={{ textAlign: 'left' }}
              value={(data && data.inherit) || ''}
              onChange={(event) => handleChange('inherit', +(event.target.value as number))}
              label={t('inherit')}>
              {Object.entries(inheritTypes()).map(([key, value]) => (
                <MenuItem value={key}>{value}</MenuItem>
              ))}
            </Select>
            <FormHelperText>{errors['inherit']}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            error={errors['preTestRisk'] !== undefined}
            fullWidth
            variant={'outlined'}
            id="preTestRisk"
            type={'number'}
            label={t('pretestRisk')}
            onChange={(event) => handleChange('preTestRisk', +event.target.value)}
            value={data && data.preTestRisk}
            helperText={errors['preTestRisk']}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            error={errors['postTestRisk'] !== undefined}
            fullWidth
            variant={'outlined'}
            id="postTestRisk"
            type={'number'}
            label={t('posttestRisk')}
            onChange={(event) => handleChange('postTestRisk', +event.target.value)}
            value={data && data.postTestRisk}
            helperText={errors['postTestRisk']}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
      </Grid>
      <FormActions actions={actions} />
    </form>
  )
}
