import {TestCycle} from "../enums/TestCycle";

export interface TestDTO {
  id: string
  testTypeID: string | undefined
  patientID: string
  createdAt: Date
  pdf?: string
  cycle: TestCycle
  progesteroneDate?: Date
  biopsyDate?: Date
  algorithmVersion?: number
  success?: boolean
  result?: string
}

export class Test {
  private _id: string
  private _testTypeID: string | undefined
  private _patientID: string
  private _createdAt: Date

  private _pdf?: string
  private _cycle: TestCycle
  private _progesteroneDate?: Date
  private _biopsyDate?: Date
  private _algorithmVersion?: number
  private _success?: boolean
  private _result?: string

  constructor(p: TestDTO) {
    this._id = p.id
    this._testTypeID = p.testTypeID
    this._patientID = p.patientID
    this._createdAt = p.createdAt
    this._pdf = p.pdf
    this._cycle = p.cycle
    this._progesteroneDate = p.progesteroneDate
    this._biopsyDate = p.biopsyDate
    this._algorithmVersion = p.algorithmVersion
    this._success = p.success
    this._result = p.result
  }

  get id(): string {
    return this._id
  }

  set id(value: string) {
    this._id = value
  }

  get testTypeID(): string | undefined {
    return this._testTypeID
  }

  set testTypeID(value: string | undefined) {
    this._testTypeID = value
  }

  get patientID(): string {
    return this._patientID
  }

  set patientID(value: string) {
    this._patientID = value
  }

  get createdAt(): Date {
    return this._createdAt
  }

  set createdAt(value: Date) {
    this._createdAt = value
  }

  get pdf(): string | undefined {
    return this._pdf
  }

  set pdf(value: string | undefined) {
    this._pdf = value
  }

  get cycle(): TestCycle {
    return this._cycle
  }

  set cycle(value: TestCycle) {
    this._cycle = value
  }

  get progesteroneDate(): Date | undefined {
    return this._progesteroneDate
  }

  set progesteroneDate(value: Date | undefined) {
    this._progesteroneDate = value
  }

  get biopsyDate(): Date | undefined {
    return this._biopsyDate
  }

  set biopsyDate(value: Date | undefined) {
    this._biopsyDate = value;
  }

  get algorithmVersion(): number | undefined {
    return this._algorithmVersion
  }

  set algorithmVersion(value: number | undefined) {
    this._algorithmVersion = value
  }

  get result(): string | undefined {
    return this._result
  }

  set result(value: string | undefined) {
    this._result = value;
  }

  get success(): boolean | undefined {
    return this._success
  }

  set success(value: boolean | undefined) {
    this._success = value;
  }



  toDTO(): TestDTO {
    return {
      id: this.id,
      testTypeID: this.testTypeID,
      patientID: this.patientID,
      createdAt: this.createdAt,
      pdf: this.pdf,
      cycle: this.cycle,
      progesteroneDate: this.progesteroneDate,
      biopsyDate: this.biopsyDate,
      algorithmVersion: this.algorithmVersion,
      result: this.result,
      success: this.success,
    }
  }
}

export function toModel(d: TestDTO): Test {
  return new Test(d)
}
