import { Container, ContainerItem } from '../common/container/Container'
import { getAppContainer } from './app'
import { moduleURLs } from '../config/api'
import {
  TEST_TYPE_API_KEY,
  TEST_TYPE_SERVICE_KEY,
  TEST_TYPES_MODULE,
  TestTypesProps,
} from '../modules/test-types/container'
import { TestTypeService } from '../modules/test-types/services/TestTypeService'
import { TestTypeApi } from '../modules/test-types/api/TestTypeApi'

let container: Container

function init(p: TestTypesProps) {
  const items = new Map<symbol, ContainerItem>()

  items.set(TEST_TYPE_API_KEY, new ContainerItem({ instance: new TestTypeApi() }))
  items.set(
    TEST_TYPE_SERVICE_KEY,
    new ContainerItem({
      instance: new TestTypeService({ apiKey: TEST_TYPE_API_KEY }),
    })
  )

  container = new Container(items, p.parentContainer, p.config)
}

export function getTestTypeContainer(): Container {
  if (!container) {
    init({
      parentContainer: getAppContainer(),
      config: {
        moduleFullUrl: moduleURLs[TEST_TYPES_MODULE],
      },
    })
  }

  return container
}
