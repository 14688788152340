import { Container } from '../../common/container/Container'

export type MatchingContainerConfig = {
    moduleFullUrl: string
}

export type MatchingProps = {
    parentContainer: Container
    config: MatchingContainerConfig
}

export const MATCHING_API_KEY = Symbol('MATCHING_API_KEY')
export const MATCHING_SERVICE_KEY = Symbol('MATCHING_SERVICE_KEY')
export const MATCHINGS_MODULE = Symbol('MATCHINGS_MODULE')
