import React from 'react'
import ListItem from '@material-ui/core/ListItem'
import { Link, LinkProps } from '@reach/router'
import { SideMenuItemComponentProps } from './types'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { COLOR_MENU_PRIMARY } from '../../routes/color-constants'

const SideBarMenuItemComponent: React.FC<SideMenuItemComponentProps> = (props) => {
  const { className, onClick, path, children, color } = props
  const classes = makeStyles((theme) =>
    createStyles({
      active: {
        color: color,
      },
      inactive: {
        color: COLOR_MENU_PRIMARY,
      },
    })
  )()

  const NavLink = <T extends object>(
    props: React.PropsWithoutRef<LinkProps<T>> & React.RefAttributes<HTMLAnchorElement>
  ) => (
    <Link
      {...props}
      getProps={({ isPartiallyCurrent }) => {
        return {
          className: className + ' ' + (isPartiallyCurrent ? classes.active : classes.inactive),
        }
      }}
    />
  )

  return (
    <>
      {!path ? (
        <ListItem
          button
          className={className + ' ' + classes.inactive}
          children={children}
          onClick={onClick}
        />
      ) : (
        <NavLink to={path}>
          <ListItem button className={className} children={children} onClick={onClick} />
        </NavLink>
      )}
    </>
  )
}

export default SideBarMenuItemComponent
