export enum MatchingType {
  Complete = 1,
  Comprehensive,
  HighFrecuency,
  HighFrecuencyXlinked,
}

export const matchingTypes = (): Record<MatchingType, string> => ({
  [MatchingType.Complete]: 'CompleteMatching',
  [MatchingType.Comprehensive]: 'Comprehensive',
  [MatchingType.HighFrecuency]: 'HighFrecuency',
  [MatchingType.HighFrecuencyXlinked]: 'HighFrecuencyXlinked',
})
